/* affichage des scores de bonne pratiques et des catégories
NB : ici c'est la version avec un cercle complet */
.score {
    --color-score: #0b7dbf;
    --bgk-score: #d6e8f3;
    display: inline-block;
    width: 2.7em;
    height: 2.7em;
    border: 0.075em solid var(--color-score);
    background: var(--bgk-score);
    color: var(--color-score);
    border-radius: 50%;
    text-align: center;
    line-height: 2.7em;
    font-weight: bold;

    .good & {
        --color-score: var(--color_good);
        --bgk-score: var(--bgk_good);
    }

    .bof & {
        --color-score: var(--color_bof);
        --bgk-score: var(--bgk_bof);
    }

    .bad & {
        --color-score: var(--color_bad);
        --bgk-score: var(--bgk_bad);
    }

    .nan & {
        --color-score: var(--grey-medium);
        --bgk-score: var(--grey-light);

        abbr {
            font-size: 0.9em;
        }

    }

}