@view-transition {
	navigation: auto;
	types: reload;
}

#logo_top,
.bigheader .logo_mc.beta {
	view-transition-name: logo;
}

::view-transition-old(logo),
::view-transition-new(logo) {
	animation-duration: .35s;
}

@import "variables";
@import "fonts";
@import "normalize";
@import "form";
@import "btn";
@import "switch";

@import "logo_mc";
@import "test_url";
@import "bigheader";
@import "loader";
@import "navigation";
@import "cards";
@import "categories";
@import "subcategory";
@import "bp";
@import "sources";
@import "footer";

@import "score";
@import "scalebar";
@import "crux_scalebar";
@import "ecoindex";
@import "graphics";

@import "table";
@import "filter_system";

@import "dialogs";
@import "dialog_comment";
@import "comment_global";
@import "dialog_eco_synthese";
@import "dialog_performance_1";
@import "dialog_poids_requetes";
@import "dialog_dom";
@import "dialog_requestmap";
@import "dialog_score_lighthouse";
@import "dialog_guide";
@import "dialog_filmstrip";
@import "dialog_question";
@import "dialog_processing";
@import "dialog_1p3p";
@import "dialog_seo";
@import "dialog_js_accesdom";

@import "loading";

@import "mode_demo";






html {
	scroll-behavior: smooth;
	scrollbar-gutter: stable;
	scrollbar-width: thin;

	&.scroll_disabled {
		overflow: hidden;

		body:after {

			opacity: 1;
			z-index: 17;
		}
	}
}

body:after {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.6);
	opacity: 0;
	backdrop-filter: blur(4px);
	z-index: -1;
	transition: all 0.15s ease-in;
}



.no-share-api .bp-share {
	display: none;
}

#wrapper .container {
	max-width: 1326px;
	margin: 0 auto;
	padding: 0 20px;
}

.logo_mc {
	color: var(--grey-medium);
	font-size: 2.125em;
}

main {
	position: relative;
	max-width: 1326px;
	margin: 0 auto;
	padding: 0 20px 1.5em;

	h2 {
		float: left;
	}
}

a {
	color: #e6513a;
}

a.invisible {
	color: inherit;
	text-decoration: none;
}

.btn_ico {
	background: none;
	border: none;
	color: var(--grey-medium);
	cursor: pointer;
	text-decoration: none;

	&:hover {
		color: #000;
	}
}



.hierarchy #view_hierarchie,
.priority #view_priorite {
	background: var(--grey-medium);
	color: var(--grey-verydark);
}



.ico.round {
	display: inline-block;
	height: 2em;
	width: 2em;
	text-align: center;
	line-height: 2em;
	background-color: #56ccf2;
	border-radius: 50%;
	color: #fff;

	&.good {
		color: #fff;
		background: var(--color_good);
	}

	&.bof {
		color: #fff;
		background: var(--color_bof);
	}

	&.bad {
		color: #fff;
		background: var(--color_bad);
	}

}

hr.sep {
	border: none;
	border-top: 1px dotted var(--grey-medium);
	background: none;
}



.tag {
	display: inline-block;
	background: var(--nightblue);
	padding: 0.5em 0.8em;
	vertical-align: middle;
	//
	font-size: 0.923em;
	line-height: 1em;
	border-radius: 3px;
	margin-right: 0.5em;

	&.bad {
		background: var(--bgk_bad);
	}

	&.bof {
		background: var(--bgk_bof);
	}

	&.good {
		background: var(--bgk_good);
	}
}

a.tag {
	color: inherit;
	text-decoration: none;
}

.bigheader .tag {
	text-transform: uppercase;
}

.avatar {
	border-radius: 50%;
	height: 3em;
	width: 3em;
}


.txt_good {
	color: var(--color_good);
}

.txt_bof {
	color: var(--color_bof);
}

.txt_bad {
	color: var(--color_bad);
}

.text_left {
	text-align: left;
}

.text_right {
	text-align: right;
}

.text_center {
	text-align: center;
}

.txt_warning {
	color: var(--color_verybad);
}

.txt_orange {
	color: var(--orange);
}

.txt_nowrap {
	white-space: nowrap;
}

.no_text_transform {
	text-transform: none !important;
}

.clear {
	clear: both;
}

.highlight {
	display: inline-block;
	background: #d0cfd5;
	padding: 0 0.25em;
	border-radius: 3px;
}

:not(button, a, [data-opendialog])[title] {
	cursor: help;
}

h2 {

	font-size: 1.5em;
	line-height: 1.1em;
	margin: 0 1em 1em 0;

}

.actions4view {
	float: right;
	text-align: right;
	margin: 0 3.5em 0 0;
	color: var(--grey-dark);

}

.scrolling {
	.actions4view {
		position: sticky;
		z-index: 12;
		top: 1.3em;
		color: #fff;
	}
}

#swicth_view {
	position: relative;
	/*
	display: inline-block;
	
	background: none;
	border: none;
	color: inherit;
	height: 22px;
	padding-left: 46px;
	font-size: 1em;

	&:before,
	&:after {
		position: absolute;
		cursor: pointer;
		content: "";

	}

	&:before {
		top: 0;
		left: 0;
		bottom: 0;
		background: #d9d9d9;
		width: 38px;
		border-radius: 11px;
	}

	&:after {
		position: absolute;
		height: 18px;
		width: 18px;
		top: 2px;
		left: 2px;
		background: var(--nightblue);
		border-radius: 50%;
		transition: all .15s ease-out 0s;
	}*/

	.priority &:after {
		background: var(--orange);
		left: 18px
	}
}

#forscroll {
	display: block;
	max-height: 1px;
	overflow: hidden;
	margin-top: -1em;
}

.bplist {
	clear: both;

}

#global_share_checkbox {
	display: none;
}

#global_share {
	display: inline-block;
	position: relative;
	margin-left: -7em;

	label {
		position: absolute;
		right: 0;
		z-index: 1;

		white-space: nowrap;
	}

	a,
	button {
		opacity: 0;
		z-index: -1;

	}
}

#global_share_checkbox:checked+#global_share {

	label {
		right: 100%;
		opacity: 0;
		transition: all .5s ease-out;
	}

	a,
	button {
		opacity: 1;
		z-index: 1;
		transition: all .5s ease-in .25s;
	}

}



.logo_snyk {
	width: 6em;
	float: right;
	margin: .5em 0 1em .5em;
}



.fp_tp {
	line-height: 22px;
}


.autopromo {
	max-width: 40em;

	&.open~#close-dialog {
		background: none;
	}

	.gridheader {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 1em;
		max-width: 40em;
		margin-top: 0em !important;

		&:before {
			content: none;
		}
	}

	p {
		margin: 0;
	}

	.note {
		text-align: center;
		font-size: .9em;
		font-weight: bold;
		color: var(--grey-medium);

		.ecoindex {
			font-size: 2.5em;
		}

		.big_gscore {
			display: block;
			margin: 0 -.2em;
			width: 100%;
			height: auto;
		}
	}

	.accroche {
		grid-column: 2/6;
		font-size: 1.6em;
		align-self: center;

		font-style: italic;
	}

	.arguments {
		grid-column: 1/6;
		margin: 0.5em 0;
		line-height: 1.4em;
		text-align: justify;
	}

	.action {
		grid-column: 1/4;
		align-self: center;
		font-size: 1.15em;
	}

	.entity {
		grid-column: 4/6;
		font-size: 1em;
		text-align: right;
	}

}

.priority .bplist {
	//padding-left:9.5em;
	display: grid;

}

#preferences {
	max-width: 650px;
	line-height: 1.6em;

	output {
		font-style: italic;
	}
}


@media (max-width: $mq-2 - 1px) {
	h2 {}
}

@media (min-width: $mq-2 ) {
	.actions4view {

		//margin: -3.75em 0 2em;
	}
}

@media (min-width: $mq-2 ) {
	.hierarchy .bplist {
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		gap: 0 1.5em;
	}


	h2 {
		font-size: 1.8em;

	}

}

@media (min-width: $mq-3 ) {
	.priority .bplist {
		//padding-left:12.5em;
	}

	h2 {
		font-size: 2.2em;

	}




	main {
		max-width: 1326px;
		text-align: center;

		h2 {
			margin-left: 1.8em;
		}

		.bplist {
			margin: 0 4em;
		}

		&>div,
		&>ul {
			text-align: left;
		}
	}

	;

	#wrapper .container {
		max-width: calc(1326px - 8em);

	}

	.hierarchy .bplist {
		gap: 0 2.5em;
	}
}

@media print {
	body {
		font-size: 10pt;
		// transform: scale(1.5);
	}

	.bplist {
		max-width: 19cm;
	}

}