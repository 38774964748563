#commentaire_globale {
    position: relative;
    margin: 6em auto 2.25em;
    max-width: 740px;
    border-radius: 1.5em;
    background: var(--grey-light);
    padding: 3em 3em 2em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 3em;

    h2 {
        font-size: 1.5em;
        margin: 0;
        grid-column: span 2;
    }

    .comment_title {
        border-bottom: 1px solid var(--grey-medium);
        margin: 0.25em 0 1em;
        font-size: 1.125em;
        padding: 0 0 0.5em;
        grid-column: span 2;
        grid-column: span 2;


        .ico {
            color: var(--orange);
            vertical-align: sub;
        }
    }

    .quoi {
        position: relative;
        grid-column: span 2;
        margin-bottom: 1em;
        line-height: 1.3em;

        &:before {
            content: "‟";
            font-size: 13em;
            color: var(--orange);
            position: absolute;
            left: -0.39em;
            top: .3em;
        }
    }

    .avatar {
        position: absolute;
        top: -3em;
        left: 50%;
        width: 6em;
        height: 6em;
        transform: translateX(-50%);
        border: 4px solid #fff;
    }

}