.listing_by_table {
    --max_cputime: 0;
    --bar_width: 8vw;

    --border-color: #000;
    --used_code: #1e2e24;
    --unused_code: #5b6d64;



    font-size: 0.8em;
    border-spacing: 0;
    position: relative;
    margin: 0;
    border-collapse: collapse;
    line-height: 0.9em;





    a {
        color: inherit;
        text-decoration-style: dotted;
        text-underline-position: under;
        text-decoration-color: #888;
    }

    a:hover {
        color: var(--orange);
        text-decoration-color: var(--orange);
    }

    .num,
    .right {
        text-align: right;
    }

    .url,
    .left {
        text-align: left;
    }

    .slim {
        width: 20px;
    }

    th {

        font-weight: 400;
        font-size: 11px;
        padding: 3px 4px;
        text-transform: uppercase;
        background: #fff;


    }

    .explication {
        white-space: normal;
        text-transform: none;
        font-size: 13px;
        line-height: 1.4em;
        margin: 0;

        .ltag {
            font-style: normal;
            margin: 0;
            vertical-align: baseline;
        }
    }

    thead {
        th {
            text-transform: uppercase;
            vertical-align: bottom;
        }

        tr {
            position: sticky;
            z-index: 3;

            &.title {
                top: -10px;
                z-index: 4;
                line-height: 14px;
            }

            &.subtitle {
                top: 17px;
                box-shadow: 0px 2px 2px -1px var(--border-color);

                //masqueur de l'ombre sur les cotés.
                th:last-child:before,
                th:first-child:before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 2px;
                    height: 20px;
                    background: #fff;

                    z-index: 13;
                }

                th:last-child:before {
                    right: -1px;
                }

                th:first-child:before {
                    left: -1px;
                }
            }
        }
    }

    tbody tr:first-child td {
        padding-top: 13px
    }

    tbody tr:last-child td {
        padding-bottom: 13px
    }

    tfoot th {
        border-top: 1px solid #000;
        padding-top: 8px;
        vertical-align: top;
        line-height: 24px;
        padding-right: 16px;

        .ltag {
            margin: 0 4px 0 16px;
        }

        .ltag:nth-of-type(1) {
            background: var(--bg1, var(--tagcolor, #ccc));
        }

        .ltag:nth-of-type(2) {
            background: var(--bg2, var(--tagcolor, #ccc));
        }

        .ltag:nth-of-type(3) {
            background: var(--bg3, var(--tagcolor, #ccc));
        }

        .ltag:nth-of-type(4) {
            background: var(--bg4, var(--tagcolor, #ccc));
        }

        .ltag:nth-of-type(5) {
            background: var(--bg5, var(--tagcolor, #ccc));
        }




    }

    .title th {
        font-weight: bold;
        font-size: 12px;
        padding-top: 10px;
    }

    .subtitle th {

        //border-bottom: 1px solid #333;
        padding-bottom: 8px;
        height: 12px;

    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    tr:hover {
        background-color: #ddd;

    }

    td {
        padding: 5px 4px;
        vertical-align: top;
        line-height: 19px;
        text-align: center;
        white-space: nowrap;
    }

    .prio_high {
        font-weight: bold;
    }

    .precision {
        display: block;
        margin: -4px 0 -6px;
        font-size: 10px;
        font-weight: 400;
    }


    .gain {
        margin-bottom: -4px;
        display: block;
        line-height: 14px;

        &.meter {
            --max: var(--max_size);
            --gain_length: calc(var(--gain_value) / var(--max) * var(--bar_width));

            //padding-left : var(--gain_length);
            &:before {
                content: "";
                display: inline-block;
                height: 5px;
                width: var(--gain_length);
                background: #1e2e24;
                border-radius: 2px;
                vertical-align: middle;
                margin: -1px 0 1px;
            }
        }
    }


    &+.actions {
        position: sticky;
        bottom: 0;
        background: #fff;
    }

    .txt_bad {
        font-weight: bold;
    }

    .nosubtitle {
        span {
            height: 1.15em;
            display: block;
        }
    }

    .dense {
        font-size: 11px;
        line-height: 1.2em;
        position: relative;
    }

    .corner_rt {
        position: absolute;
        right: 5px;
        top: 4px;
    }

    .code {
        font-family: monospace;
    }

    .forcewrap {
        white-space: normal;
        max-width: 40em;
    }

    .forcebrword {
        word-break: break-word;

    }

    tr.kpi {

        border-top: 3px solid var(--tagcolor);


        &> :first-child {
            position: relative;


            &:before {
                content: var(--txt);
                position: absolute;
                left: -30px;
                top: -11px;

                border-radius: 4px;
                border-right: none;
                background: var(--tagcolor);
                color: #fff;
                padding: 0 2px 0 4px;
                font-size: 11px;
                font-weight: 700;
                display: inline-block;
                line-height: 18px;
                min-height: 18px;
                min-width: 24px;
                text-align: center;
                box-sizing: border-box;
                z-index: 2;

            }

            &:after {
                content: "";
                position: absolute;
                top: -11px;
                left: -4px;
                width: 0;
                height: 0;
                border-color: transparent transparent transparent var(--tagcolor);
                border-style: solid;
                border-width: 9px 0 9px 10px;
            }
        }

        &.fcp {
            --tagcolor: #495c87;
            --txt: "FCP";
        }

        &.lcp {
            --tagcolor: #0eb724;
            --txt: "LCP";
        }


    }
}

.JS_access_dom_table,
.links_table,
.schemas_table,
.headingsmap,
.ogp_data,
.keywords_table,
.eco_domains_table,
.polices,
.CSS_table,
.JS_table,
.JS_vulnerability,
.JS_console,
.images,
.requetes_table,
.v1p3p_table,
.domains_table,
.dom_validation {

    &.listing_by_table {
        width: 100%
    }
}

.images {

    tr {

        &.title th+th,
        > :nth-child(5),
        > :nth-child(6),
        > :nth-child(7),
        > :nth-child(10) {
            border-left: 1px solid #ccc;
            padding-left: 15px;
        }

        &.title :nth-child(2),
        > :nth-child(4),
        > :nth-child(5),
        > :nth-child(6),
        > :nth-child(9) {

            padding-right: 15px;
        }



    }

    tfoot th+th {
        border-left: 1px solid #ccc;
        padding-left: 15px;
        padding-right: 5px;
    }
}


.polices,
.CSS_table,
.JS_table {

    tfoot th:nth-child(2),
    tfoot th:nth-child(3) {
        border-left: 1px solid #ccc;
    }
}

.head_elements {

    tr.title> :nth-child(2),
    tr.subtitle>:nth-child(2),
    tr.subtitle>:nth-child(3),
    tr>td:nth-child(2),
    tr>td:nth-child(4) {
        border-left: 1px solid #ccc;
        padding-left: 20px;
    }

    tr.title> :nth-child(1),
    tr> :nth-child(1) {
        padding-left: 20px;
    }

    tr> :nth-child(1),
    tr>td:nth-child(3) {
        padding-right: 20px;
    }

    tr>td:nth-child(4) {
        white-space: normal;
        word-break: break-word;
    }
}


.polices,
.CSS_table,
.JS_table {
    tr {

        &.title :nth-child(2),
        &.title :nth-child(3),
        > :nth-child(7),
        > :nth-child(11) {
            border-left: 1px solid #ccc;
            padding-left: 20px;
        }

        > :nth-child(6),
        > :nth-child(10) {

            padding-right: 20px;
        }

    }
}

.JS_vulnerability {
    tr {

        &.title :nth-child(2),

        > :nth-child(3),
        > :nth-child(11) {
            border-left: 1px solid #ccc;
            padding-left: 20px;
        }

        > :nth-child(2) {

            padding-right: 20px;
        }

    }
}

.CSS_table {
    tr> :nth-child(13) {

        padding-right: 40px;
    }
}

.links_table {
    --bar_width: 12em;



    tr.title :nth-child(2),
    tr>th:nth-child(6),
    tr>td:nth-child(6),
    tr.title :nth-child(3),
    tr>th:nth-child(9),
    tr>td:nth-child(10),
    tfoot th:nth-child(2) {
        border-left: 1px solid #ccc;
        padding-left: 20px;
    }

    tr>td.url,
    tr>td:nth-child(5) {
        white-space: normal;
        word-break: break-word;
    }


    .code {
        font-family: monospace;
    }


}

.JS_access_dom_table {

    tr>td:nth-child(1),
    tr> :nth-child(3) {

        padding-right: 20px;
    }

    tr.title> :nth-child(2),
    tr.subtitle> :nth-child(3),
    tr>td:nth-child(4) {
        border-left: 1px solid #ccc;
        padding-left: 20px;
    }

    tr>td:nth-child(5) {
        text-wrap: inherit;
    }

    .metric {
        background: #eaf1ff !important;

        strong,
        em {
            font-size: 14px;
        }
    }

}

.keywords_table {
    --bar_width: 20em;


    tfoot tr :nth-child(2),
    tr.title :nth-child(2),
    tr> :nth-child(4) {
        border-left: 1px solid #ccc;
        padding-left: 20px;
    }

    tr> :nth-child(3) {

        padding-right: 40px;
    }


    tbody {
        tr> :nth-child(2) {
            width: 50%;
            white-space: nowrap;
        }
    }
}

.poids_requetes {
    tr {
        > :nth-child(5) {
            border-left: 1px solid #ccc;
            padding-left: 20px;
        }

        > :nth-child(4) {

            padding-right: 40px;
        }
    }
}

.requetes_table {
    --bar_width: 10em;

    tr {

        &.title :nth-child(2),
        &.title :nth-child(3),
        &.title :nth-child(4),

        > :nth-child(9),
        >th:nth-child(10),
        >th:nth-child(12),
        >td:nth-child(11),
        >td:nth-child(13) {
            border-left: 1px solid #ccc;
            padding-left: 10px;
        }

        > :nth-child(8),
        > :nth-child(9),
        > :nth-child(10),
        > :nth-child(12) {

            padding-right: 10px;
        }
    }

    .content-type {
        font-size: 90%;
        display: inline-block;
        line-height: 1em;
    }
}

.v1p3p_table {
    tr {

        &.title th:not(:first-child),
        &.subtitle>:nth-child(3),
        >td:nth-child(3),
        >th:nth-child(8),
        >td:nth-child(9),
        >th:nth-child(10),
        >td:nth-child(11) {
            border-left: 1px solid #ccc;
            padding-left: 20px;
        }

        &.title th:nth-child(3),
        > :nth-child(5) {
            border-left: 2px solid var(--grey-medium);
            padding-left: 20px;
        }

        > :nth-child(2),
        > :nth-child(4),
        > :nth-child(8),
        > :nth-child(10) {

            padding-right: 10px;
        }

        > :nth-child(4) {

            padding-right: 20px;
        }
    }

    .iw {
        font-size: 1.1em;
        font-weight: 700;

        .bar {
            height: 2em;
        }
    }

    tfoot {
        tr {

            > :nth-child(3),
            > :nth-child(4),
            > :nth-child(5) {
                border-left: 1px solid #ccc;

            }

            > :nth-child(2) {
                border-left: 2px solid var(--grey-medium);

            }


        }

    }
}

.domains_table {
    tr {

        &.title th:not(:first-child),
        >:nth-child(4),
        >:nth-child(10) {
            border-left: 1px solid #ccc;
            padding-left: 15px;
        }

        > :nth-child(3),
        > :nth-child(9) {

            padding-right: 15px;
        }

        > :nth-child(14) {

            padding-right: 25px;
        }
    }

    tfoot th+th {
        border-left: 1px solid #ccc;

    }

    .iw {
        font-size: 1.1em;
        font-weight: 700;

        .bar {
            height: 2em;
        }
    }
}

.JS_console {

    td,
    th {
        padding-left: 10px;
        padding-right: 10px
    }

    tr {

        &.title th:not(:first-child),
        >:nth-child(4) {
            border-left: 1px solid #ccc;
            padding-left: 20px;
        }

        > :nth-child(3) {

            padding-right: 20px;
        }


    }

}

td {

    .tag_nc,
    .tag_nc_1ko,
    .tag_br,
    .tag_gz {
        margin-right: -2px;
    }

    .ltag+.gain {
        margin-right: -2px;
        text-align: right;
    }
}

th.sort {
    position: relative;
    cursor: pointer;

    &:hover {
        background: #efefef;
    }
}

th[aria-sort="descending"]:after,
th[aria-sort="ascending"]:after {
    content: "";
    position: absolute;
    height: 6px;
    width: 6px;
    border-right: 1px solid #000;
    top: 20px;
    z-index: 5;
    background: inherit;
    left: calc(50% - 3px);
    background: linear-gradient(135deg, transparent, transparent 40%, #fff 40%, #fff 100%);
    border-bottom: 1px solid #000;
}

th[aria-sort="descending"]:after {
    transform: rotate(45deg);
}

th[aria-sort="ascending"]:after {
    transform: rotate(-135deg);
}