/* contenu de popin spécifique */


.eco_domains_table {
    .ico.round {
        font-size: 1em;
        margin-left: 2em;
    }

    .explication {
        max-width: 30em;
    }

    .domains_table tr>:nth-child(3) {
        width: 30em;
    }
}

.eco_synthese {


    .ico {
        float: left;
        margin-right: 0.5em;
    }

    .conso {




        .equivalence {
            margin: 0;
            font-size: 0.9em;

        }

        p {
            margin: 0 0 1.5em;
        }
    }

    .hebergement {
        .indicateur {
            margin-bottom: 1.5em;
            display: block;
            line-height: 1.4em;
            clear: both;

            .ico {
                display: block;
                margin-bottom: 0.25em
            }
        }




    }

    strong {
        font-size: 1.35em;
    }

    .ecoscale {
        font-size: 1em;
        width: 100%;


        &.with-ecoindex {
            margin: 2.5em 0 2em;

        }
    }
}