/* liste des catégories */
.categories {
    --color: var(--grey-dark);
    --active-color: #000;
    clear: both;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(6, auto);
    gap: 1em;
    margin: 1.5em 0;
    padding: 0;
    color: var(--color);

    border-bottom: 1px solid var(--grey-medium);

    label {
        display: block;
        cursor: pointer;
        margin-bottom: -1px;
        padding-bottom: .75em;
        text-align: center;
        opacity: 0.75;
        white-space: nowrap;

        &:after {
            content: attr(data-title);

            display: block;
            height: 0;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: 700;
            padding-right: 54px;
        }
    }

    //font-weight:bold;


    li {
        display: inline-block;
        position: relative;

        cursor: pointer;

    }

    .gscore {
        vertical-align: middle;
        cursor: pointer;

        transition: 0.25s all linear;
        pointer-events: none;

    }

    li:hover {
        color: var(--active-color);
    }

    .active {
        color: var(--active-color);
        font-weight: 700;

        label {
            border-bottom: 3px solid var(--active-color);
            opacity: 1;


        }
    }

    .active {
        //font-weight: bold;
    }
}

// block categorie
.categorie {
    line-height: 1.6em;
    margin-bottom: 2em;
    //display:none;
}

.bplist {
    //display:none;
}

.select_categorie {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -2;
    top: 10em;
}

.select_categorie:not(:checked)+.categorie,
.select_categorie:not(:checked)+.categorie+.bplist {
    display: none !important;
}



@media (max-width: $mq-1 - 1px) {
    .categories {
        overflow-y: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory;

        li {
            scroll-snap-align: start;
        }
    }
}

@media (min-width: $mq-1 ) {
    .categories {

        li {
            margin-right: 0em;
        }

        .gscore {
            margin-left: 0.5em;
        }
    }



}

@media (min-width: $mq-2 ) {
    .categories {

        li {
            margin-right: 1em;
        }
    }

    .scrolling {
        .categories {
            --color: #ddd;
            --active-color: #fff;
            position: sticky;
            z-index: 11;
            font-size: 1em;
            top: 0;
            display: block;
            transition: max-height 0.25s ease-out;
            border-bottom: none;
            margin-left: 3em;
            //text-align: center;

            li {
                margin-right: .5em;


                label {
                    &:after {
                        padding-right: 38px;
                    }
                }
            }

            li.active {

                label {
                    padding: 1em 0 .675em;
                    border-color: var(--orange);
                }
            }

            .gscore {
                height: 34px;
                width: 34px;
            }


        }

    }

}

@media (min-width: $mq-3 ) {

    .categories {
        display: block;
        font-size: 1.375em;
        margin: 1.5em 2.9em;



        li {
            margin-right: 2em;
        }

    }

    .scrolling {
        .categories {
            text-align: center;

            li {
                margin-right: 2em;
            }
        }
    }

    .categorie {
        margin-left: 4em;
        margin-right: 4em
    }
}