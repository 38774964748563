[for="hide_metrics"] {
    position: absolute;
    right: 140px;
    top: 153px;
    opacity: 1;
}

#openclose_filtersystem:checked~.filteredform [for="hide_metrics"] {
    opacity: 0;

}

#hide_metrics:checked~table tr.metric {
    display: none;
}