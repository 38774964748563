.loader {
    --ld_size : 8em;
    --ld_border : 1em;

    display: inline-block;
    width: var(--ld_size);
    height: var(--ld_size);;
}
.loader:after {
    content: " ";
    display: block;
    width: var(--ld_size);
    height: var(--ld_size);
    border-radius: 50%;
    border:  var(--ld_border) solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loader 2s linear infinite;
}
@keyframes loader {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}