/* contenu de popin spécifique */


//nécessaire pour supprimer la légère bordure affichée dans l'iframe de requestmap.
dialog .content.foriframe {
    overflow: hidden;
    height: calc(100vh - 11em);
    padding: 0;

    width: auto;
    max-height: none;


    iframe {
        position: relative;
        z-index: 1;
        border: none;
        overflow: hidden;
        width: calc(100% + 2px);
        height: calc(100vh - 11em + 2px);
        margin: -1px;
    }

}