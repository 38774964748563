/* contenu de popin spécifique */
.score_lighthouse {
    p {
        position: relative;
        z-index: 2;
    }

    iframe {
        position: relative;
        z-index: 1;
        border: none;
        overflow: hidden;
        width: 100%;
        margin: -25px auto 0;
        min-width: 900px;
        height: 490px
    }
}