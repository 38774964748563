/* système d'affichage d'une valeur sur  son échelle 
en fonction des seuils fournis */
.scalebar {

    --size: 12px;
    --height: 1.666em;
    --width: 16.666em;
    --pad: .5%;

    font-size: var(--size);

    width: var(--width);
    height: var(--height);
    position: relative;

    display: inline-block;
    box-sizing: border-box;
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    margin: 2.5em 0;

    border-radius: calc(var(--height)/2);
    padding: 0 var(--pad);
    border-width: 0 calc(var(--height) / 1.5);
    border-style: solid;

    &.inf {

        //--sector1 : calc( var(--is_ok) / var(--is_abnormal) * 100% );
        //--sector2 : calc( max( (var(--is_bad) / var(--is_abnormal) * 100% ) , 1.75em ) );

        --sector1: 30%;
        --sector2: 70%;

        //background-image: linear-gradient(90deg, var(--color_good) var(--sector1), var(--color_bof) var(--sector1), var(--color_bof) var(--sector2), var(--color_bad) var(--sector2));
        background-image: linear-gradient(90deg, var(--color_good) var(--sector1), var(--color_bof) var(--sector1), var(--color_bad) var(--sector2), var(--color_verybad) var(--sector2));

        border-left-color: var(--color_good);
        border-right-color: var(--color_verybad);
    }

    &.sup {

        --max: calc(max(var(--is_ok), var(--value)));
        --min: calc(min(var(--is_abnormal)));

        //--sector2: calc((var(--is_ok) - var(--min)) / (var(--max) - var(--min)) * 100%);
        //--sector1: calc(max(((var(--is_bad) - var(--min)) / (var(--max) - var(--min)) * 100%), 1.75em));
        --sector1: 30%;
        --sector2: 70%;

        background-image: linear-gradient(90deg, var(--color_verybad) var(--sector1), var(--color_bad) var(--sector1), var(--color_bof) var(--sector2), var(--color_good) var(--sector2));

        border-left-color: var(--color_verybad);
        border-right-color: var(--color_good);

    }

    /* si is_ok = 0 et is_bad = is_abnormal = 1*/
    &.inf.bin {
        background-image: linear-gradient(90deg, var(--color_good) 50%, var(--color_verybad) 50%);

    }

    /* si is_ok = 1 et is_bad = is_abnormal = 0*/
    &.sup.bin {
        background-image: linear-gradient(90deg, var(--color_verybad) 50%, var(--color_good) 50%);

    }

    &.inf:not(.zero_is_good):before,
    span {
        position: absolute;
        width: 4em;
        transform: translateX(-50%);
        top: calc(var(--height) + 0.333em);
        text-align: center;
        left: 0;
    }

    &.inf:not(.zero_is_good):before {
        content: "0";
        left: var(--pad);
    }

    /*.value {

        left: calc(min(100% + 1.2em, var(--value) / var(--is_abnormal) * 100%));
    }*/

    //il faut distinguer les class good, bof et bad qui sont en rapport avec la note
    //des class val_good, val_bof et val_bad en rapports avec la position de la valeur.

    &.val_good .value {
        left: calc(var(--value) / (var(--is_ok)) * (var(--sector1) - var(--pad)) + var(--pad));
    }

    &.zero_is_good.val_good .value {
        left: 29%;
    }

    &.val_bof .value {
        left: calc((var(--value) - var(--is_ok)) / (var(--is_bad) - var(--is_ok)) * 40% + 30%);
    }

    &.val_bad .value {

        left: calc(min(100%, (var(--value) - var(--is_bad)) / (var(--is_abnormal) - var(--is_bad)) * (var(--sector1) - var(--pad)) + 70%));
    }

    &.sup {
        &.val_good .value {
            --seuil_ho: calc(var(--is_ok) / 70 * 100);
            left: calc(min(100%, (var(--value) - var(--is_ok)) / (var(--seuil_ho) - var(--is_ok))* (var(--sector1) - var(--pad)) + 70%));
        }

        &.val_bof .value {
            left: calc((var(--value) - var(--is_bad)) / (var(--is_ok) - var(--is_bad)) * 40% + 30%);
        }

        &.val_bad .value {

            left: max(0%, (var(--value) - var(--is_abnormal))/(var(--is_bad) - var(--is_abnormal))*(var(--sector1) - var(--pad)) + var(--pad));
        }

    }





    &.inf .is_ok,
    &.sup .is_bad {
        left: var(--sector1);
    }

    &.inf .is_bad,
    &.sup .is_ok {
        left: var(--sector2);
    }

    &.bin.inf.val_bad .value,
    &.bin.inf .is_bad,
    &.sup.bin.val_good .value,
    &.sup.bin .is_ok,
    &.inf .is_abnormal {
        left: calc(100% - var(--pad));
    }

    &.bin.inf.val_good .value,
    &.bin.inf .is_ok,
    &.sup.bin.val_bof .value,
    &.sup.bin .is_bad,
    &.sup.bin .is_abnormal,
    &.sup .is_abnormal {
        left: var(--pad);
    }


    &::after {

        content: var(--value_label);
        display: inline;
    }


    .value {
        border-radius: 0.333em;
        padding: 0 0.5em;
        text-align: center;
        width: auto;
        height: 1.8em;
        color: black;
        border: 1px solid #000;
        background-color: white;
        line-height: 2em;
        transform: translateX(-50%);
        white-space: nowrap;
        min-width: 1.333em;
        font-weight: bold;
        top: -2.2em;

        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            width: 0;
            height: 0;
            top: 100%;
            left: 50%;
            border: 0.5em solid transparent;
            border-top-width: .75em;
            border-top-color: #000;
            transform: translateX(-50%);

        }
    }

}

@media (max-width: $mq-1 - 1px) {
    .scalebar {
        --width: 80%;
        margin: 2.5em auto;

    }

}

@media (min-width: $mq-1 ) {
    .scalebar {
        --width: 100%;
    }

}

@media (min-width: $mq-3 ) {
    .scalebar {
        margin: 3.5em 0;
    }
}