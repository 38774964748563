/* affichage de l'ecoindex et de son échelle */
:root {
    --lvlA: #2e9b43;
    --lvlB: #34bc6e;
    --lvlC: #cadd00;
    --lvlD: #f7ed00;
    --lvlE: #ffce00;
    --lvlF: #fb9929;
    --lvlG: #f01c16;
}

.ecoindex {
    display: inline-block;
    height: 3em;
    width: 3em;
    text-align: center;
    line-height: 3em;
    background-color: #ccc;
    border-radius: 50%;
    font-weight: bold;
    color: #000;

    &.lvlA {
        background: var(--lvlA);
    }

    &.lvlB {
        background: var(--lvlB);
    }

    &.lvlC {
        background: var(--lvlC);
    }

    &.lvlD {
        background: var(--lvlD);
    }

    &.lvlE {
        background: var(--lvlE);
    }

    &.lvlF {
        background: var(--lvlF);
    }

    &.lvlG {
        background: var(--lvlG);
    }
}

.ecoscale {
    position: relative;
    margin: 2.5em 0 4em;
    width: 21em;
    height: 1em;
    border-radius: 0.5em;
    background: linear-gradient(90deg,
            var(--lvlA) 15%,
            var(--lvlB) 15%,
            var(--lvlB) 29%,
            var(--lvlC) 29%,
            var(--lvlC) 43%,
            var(--lvlD) 43%,
            var(--lvlD) 57%,
            var(--lvlE) 57%,
            var(--lvlE) 71%,
            var(--lvlF) 71%,
            var(--lvlF) 85%,
            var(--lvlG) 85%,
            var(--lvlG) 100%);

    .ecoindex {
        position: relative;
        z-index: 2;
        top: -1em;

        transform: scale(1.3);
    }

    .value,
    .ecoindex {

        &.lvlB {
            margin-left: 15%;
        }

        &.lvlC {
            margin-left: 29%;
        }

        &.lvlD {
            margin-left: 43%;
        }

        &.lvlE {
            margin-left: 57%;
        }

        &.lvlF {
            margin-left: 71%;
        }

        &.lvlG {
            margin-left: 85%;
        }



    }

    .value {
        border-radius: 0.333em;
        padding: 0 0.5em;
        text-align: center;
        width: auto;
        height: 1.8em;
        color: black;
        border: 1px solid #000;
        background-color: white;
        line-height: 2em;
        transform: translateX(-50%);
        white-space: nowrap;
        min-width: 1.333em;
        font-weight: bold;
        top: -2.2em;
        left: 7.5%;
        display: inline-block;
        position: absolute;
        font-size: 0.75em;

        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            width: 0;
            height: 0;
            top: 100%;
            left: 50%;
            border: 0.5em solid transparent;
            border-top-width: 0.75em;
            border-top-color: #000;
            transform: translateX(-50%);

        }
    }

    .tag {
        font-size: 0.7em;
        margin-top: 2em;
        background: var(--grey-light);
    }

    small {
        position: relative;
        top: 0.5em;
        font-size: 0.65em;
        color: var(--grey-dark);
    }
}