/* affichage des commentaires en popin */
dialog .container.comment {

    line-height:1.4em;

    .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2em 0;
    }

    .comment_title {
        border-bottom: 1px solid var(--grey-light);
        margin: 0.25em 0 0;
        font-size: 1.125em;
        padding: 0 0 0.5em;
        grid-column: span 2;


        .ico {
            color: var(--orange);
            vertical-align: sub;
        }
    }   
  
    .qui {
        margin:0;
        padding: 1em;
        line-height: 1.3em;   
       

        img {
            float: left;
            font-size: 1.5em;
            margin: -0.6em 1em -0.65em -0.65em;
        }
       
    }
    .quoi {
        max-width:650px;
        position: relative;
        grid-column: span 2;

        &:before {
            content: "‟";
            font-size: 5em;
            color: var(--orange);
            position: absolute;
            left: -0.5em;
            top: 0.3em;

        }
        :first-child {
            margin-top:0;
        }
    }
    .action{
        align-self: center;
    }
}