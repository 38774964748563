/* système d'affichage d'une valeur sur  son échelle 
en fonction des seuils fournis */
.crux_scalebar {
            
    --size :11px;
    --height : 2.25em;
    --width : 100%;
    
    font-size: var(--size);

    width:var(--width);
    height :var(--height);
    position:relative;

    display: inline-block;
    box-sizing: border-box;
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    margin: 2.25em 0 0.65em;

    border-radius : 4px;
    border-width: 0 ;
    
    
    

    //--is_good: 87 ; --is_medium: 10;
    --sector1 : var(--is_good);
    --sector2 : calc( var(--is_good) + var(--is_medium) );

    background-image: linear-gradient(90deg, var(--color_good) var(--sector1), var(--color_bof) var(--sector1), var(--color_bof) var(--sector2), var(--color_bad) var(--sector2));
    //background-image: linear-gradient(90deg, var(--color_good) var(--is_good), var(--color_bof) var(--is_good),  var(--color_bof) var(--is_medium), var(--color_verybad) var(--is_medium) );
    

   
    

    span {
        position: absolute;
        width: 4em;
        top: 0.6em;
        color :#fff;
    }   
       
    .value {
          
        left : 75%;
    }
    .is_good {
        left : 0.5em;
    }

    .is_medium {
        left : calc( var(--is_good) + 0.5em)
    }
    .is_bad {
        left : calc( var(--is_good) + var(--is_medium) + 0.5em);
        color: #000;
    }


    .value {
        border-radius: 0.333em;
        padding: 0 0.5em;
        text-align: center;
        width: auto;
        height: 1.8em;
        color: black;
        border: 1px solid #000;
        background-color: white;
        line-height: 2em;
        transform: translateX(-50%);
        white-space: nowrap;
        min-width: 1.333em;
        font-weight: bold;
        top:-2.2em;       

        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            width: 0;
            height: 0;
            top:100%;
            left: 50%;
            border: 0.5em solid transparent;
            border-top-width: .75em;
            border-top-color: #000;           
            transform: translateX(-50%);
            
        }
    }

}
/*
@media (max-width: $mq-1 - 1px ) { 
    .crux_scalebar { 
        --width : 80%;
        margin:2.5em auto;

    }

}
@media (min-width: $mq-1 ) { 
    .crux_scalebar { 
        --width : 100%;
    }

}
@media (min-width: $mq-3 ) { 
    .crux_scalebar{
        margin:3.5em 0;
    }
}*/