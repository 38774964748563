.resumebase64inline,
.resumesvginline {
    background: repeating-linear-gradient(-45deg,
            #f2f2f2,
            #f2f2f2 4px,
            #fff 4px,
            #fff 8px);
}

.resumebase64inline.close~.base64inline,
.resumesvginline.close~.svginline {
    display: none;
}

.headorder_bar {
    list-style: none;
    border-radius: 4px;
    display: grid;
    grid-auto-flow: column;
    margin: 0 auto;
    padding: 0;
    height: 1.64em;
    line-height: 1.64em;
    color: #ddd;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 30px;
    //overflow: hidden;

    li {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        background: var(--bg1);

        &:hover {
            margin: -1px;
            z-index: 2;
            box-shadow: 1px 1px 5px #333;
        }
    }

    :first-child {
        border-radius: 4px 0 0 4px;
    }

    :last-child {
        border-radius: 0 4px 4px 0;
    }



    &+.legendes {
        columns: 4;
        margin-bottom: 0;
    }

}

.prio_10 {
    --bg1: var(--bg10);
}

.prio_9 {
    --bg1: var(--bg9);
}

.prio_8 {
    --bg1: var(--bg8);
}

.prio_7 {
    --bg1: var(--bg7);
}

.prio_6 {
    --bg1: var(--bg6);
}

.prio_5 {
    --bg1: var(--bg5);
}

.prio_4 {
    --bg1: var(--bg4);
}

.prio_3 {
    --bg1: var(--bg3);
}

.prio_2 {
    --bg1: var(--bg2);
}

.prio_1 {
    //--bg1: var(--bg1);
}

.prio_0 {
    --bg1: var(--bg0);
}