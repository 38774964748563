.headingsmap {
    margin-top: -17px;

    &.listing_by_table td {
        white-space: normal;
    }

    .h2 {
        padding-left: 1em;
    }

    .h3 {
        padding-left: 2em;
    }

    .h4 {
        padding-left: 3em;
    }

    .h5 {
        padding-left: 4em;
    }

    .h6 {
        padding-left: 5em;
    }

    .error {
        font-weight: bold;
        color: var(--color_bad);

        em {
            font-weight: normal;
        }
    }

}

.ogp_data {
    thead tr.subtitle {
        position: static;
    }

    td {
        white-space: normal;
        min-width: 100px;
    }

    td+td {
        width: 80%;
    }
}

.preview_fb {
    max-width: 524px;
    border: 1px solid #ddd;

    figure {
        padding-top: 52.5%;
        width: 100%;
        position: relative;
        margin: 0;
        height: 0;
        overflow: hidden;

    }

    img {
        position: absolute;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    &>div {
        border-top: 1px solid #ddd;
        padding: 10px;
        background: #f2f3f5;

        p {
            margin: 0;
        }

        .domain {
            font-size: 12px;
            text-transform: uppercase;
            color: #71777f;
        }

        .title {
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #000;
            margin-top: 5px;
        }

        .description {
            font-size: 14px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #71777f;
            margin-top: 3px;
        }
    }
}

.js_nojs_container {
    position: absolute;
    top: 6.65em;
    right: 3em;
    z-index: 10;

    .ltag {
        --tagcolor: var(--grey-medium);
        cursor: pointer !important;
    }

    #js_nojs_4js:checked {
        &~[for="js_nojs_4js"] {
            --tagcolor: #48227a;
        }

    }

    #js_nojs_4nojs:checked {
        &~[for="js_nojs_4nojs"] {
            --tagcolor: #48227a;
        }

    }
}

.content:has(#js_nojs_4js:checked) {
    .nojs {
        display: none !important;
    }
}

.content:has(#js_nojs_4nojs:checked) {
    .js {
        display: none !important;
    }
}

.seo_content {


    .nojs_lang {
        font-size: 14px;
        line-height: 22px;

        img {
            height: 18px;
            border-radius: 3px;
            overflow: hidden;
            width: 28px;
            vertical-align: sub;
        }
    }

    ol.explication {
        margin-top: -1em;
        padding: 0 0 0 1.5em;
    }

    /* .nojs_lang .nojs,
    .legende .js {
        display: none;
    }

    .nojs_lang .js,
    .legende .nojs {
        display: inline-block;
    }

    &:has(#js_nojs_4js:checked) {

        .nojs_lang .nojs,
        .legende .js {
            display: inline-block;
        }

        .nojs_lang .js,
        .legende .nojs {
            display: none;
        }
    }*/

    #smallbar4words,
    &:has(#js_nojs_4js:checked) #bigbar4words {
        --max: var(--wordcount_js);
        --value1: var(--wordcountrelevant_js);
        --value2: calc(var(--wordcountcore_js) - var(--value1));
    }

    #bigbar4words,
    &:has(#js_nojs_4js:checked) #smallbar4words {
        --max: var(--wordcount_nojs);
        --value1: var(--wordcountrelevant_nojs);
        --value2: calc(var(--wordcountcore_nojs) - var(--value1));

    }

    #bigbar4words,
    #smallbar4words {
        transition: --value1 250ms ease;
        --value3: calc(var(--max) - var(--value1) - var(--value2))
    }

}


.seo_schema {
    h4 {
        margin-bottom: 0.5em;
    }
}

.schemas_table {
    ul {
        list-style: none;
        margin: 0;
        line-height: 1.2em;
        white-space: normal;
    }

    td>ul {
        padding: 0;
    }

    .refid {
        color: #0070ff
    }

    .reftype {
        color: #eb04b7
    }

    img {
        max-width: 150px;
        height: auto;
    }

    .forcebrword {
        max-width: 20em;
        white-space: normal;
    }

    pre {
        font-family: Arial, Helvetica, sans-serif;
        margin: 0;
        white-space: pre-wrap;
    }

}

.seo_popularity {
    .grid {
        gap: 0 4em;
    }

    h4 {
        margin: 0 0 10px;
    }

    .indicateur.inline {
        display: inline-block;
        margin-right: 1.5em;
    }

    .legendes.short {
        columns: 4;
        margin-bottom: 9px;
    }

    .donuts {
        .precision {
            color: #333;
        }
    }

    .mini_donuts .graph_donuts {

        font-size: 0.85em;

        .donuts {
            --radial: radial-gradient(#eee 53.5%, transparent 55%);


        }


    }

    .grid.encart.mini_donuts {
        position: relative;
    }

    .ratio_tfcf {
        position: absolute;
        display: block;
        width: 8em;
        border: 1px dotted var(--grey-medium);
        border-radius: 1em;
        text-align: center;
        padding: 0.75em .5em;
        left: calc(66.6% - 4.5em);
        top: 5.4em;

        h4 {
            margin: 0 0 .5em;
            position: relative;
            z-index: 2;
        }

        .reperes {
            border-top: 1px dotted var(--grey-medium);
            text-align: left;
            margin: 0.25em auto;
            padding: .75em 0.75em 0;
            max-width: 90px;
            position: relative;
            z-index: 2;
        }


    }

    .tf_themes {
        list-style: none;
        font-size: 14px;
        line-height: 22px;
        margin: 0;
        padding: 0;


        .tf_score {
            display: inline-block;
            color: #fff;
            width: 2em;
            height: 1.5em;
            line-height: 1.5em;
            border-radius: 4px;
            text-align: center;
            vertical-align: middle;

            &.good {
                background: var(--color_good);
            }

            &.bof {
                background: var(--color_bof);
            }

            &.bad {
                background: var(--color_bad);
            }
        }
    }

    .border_right {
        border-right: 1px dotted var(--grey-medium);
        padding-right: 2em;
        margin-right: -2em;

    }

}

@media (min-width: $mq-3 ) {
    .seo_pagemap {
        .sticky {
            position: sticky;
            top: 0
        }

        .sticky+[class*='col-'] {
            grid-row: span 2;
        }
    }
}