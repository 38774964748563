/* affichage des sous-catégories */
.subcategory {
    //padding-bottom:2.5em;

    background: var(--grey-light);
    padding: 1.25em 1.25em 1.25em 6.875em;
    border-radius: 1em;
    position: relative;
    text-align: left;


    .subcategory_title {
        display: inline-block;
        text-transform: uppercase;
        font-size: 1em;
        color: var(--grey-dark);

    }

    &.good,
    &.good .subcategory_title {
        background: var(--bgk_good);
    }

    &.bof,
    &.bof .subcategory_title {
        background: var(--bgk_bof);
    }

    &.bad,
    &.bad .subcategory_title {
        background: var(--bgk_bad);
    }

    .btn {
        font-size: 0.7em;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff !important;

        &:hover {
            opacity: 1;
        }

    }

    /* &.good {
        .btn {
            background: var(--color_good);
        }
    }

    &.bof {
        .btn {
            background: var(--color_bof);
        }
    }

    &.bad {
        .btn {
            background: var(--color_bad);
        }
    }*/

}

.subcategory_description>div {
    font-size: 1.125em;
    line-height: 1.5em;
    font-style: italic;

}

.priority {
    .subcategory {
        grid-column: auto;
        order: -100000;
        margin: 0 0 1em;
        display: none;

    }

    .subcategory_description {
        display: none;
    }
}


@media (max-width: $mq-2 - 1px) {
    .subcategory {
        margin-top: 1.5em;

        .btn {
            margin-top: -0.5em;
            float: right;
        }
    }

    .subcategory_score {
        font-size: 1.6em;
        position: absolute;
        left: 0.78em;
        top: 0.525em;
    }



}

@media (min-width: $mq-1) {}

@media (min-width: $mq-2) {
    .subcategory {
        min-width: 8em;
        //--dscrpt_count : 1;
        --rowspan: calc(var(--bp_count) + 1);

        padding: 0 0 2em;
        text-align: center;
        grid-row: span var(--rowspan);
        grid-column: 1;


        .hierarchy &:not(:first-child) {
            margin-top: 2.5em;

            &+.subcategory_description,
            &+.bp {
                margin-top: 2.5em;
            }
        }

        h4 {
            position: sticky;
            top: 4em;
            z-index: 2;
        }

        .subcategory_title {
            display: inline-block;
            font-size: 0.8em;
            margin-top: 7em;
        }

        .subcategory_score {
            display: block;
            font-size: 1.75em;
            margin: -3.6em auto 0;

        }

        .btn {
            margin-top: 2.5em;
        }

    }

    .priority {
        .subcategory {
            min-height: 9em;
            margin: 0 1em 2em 0;
        }
    }

    .subcategory_description {
        //max-width: 762px;
        grid-column: span 7;
        min-height: 4em;

        .btn {
            float: right;
            font-size: 0.9em;
            margin-top: 1.2em;

            .ico {
                font-size: 1em;
            }
        }
    }
}