/* contenu de popin spécifique */
.metriques {



    .indicateurs {
        display: grid;
        grid-template-columns: 20% 75% 5%;
    }


    .cvw {
        border-radius: 1em 0 0 1em;
        background: #cccccc66;
        margin: 1em -1.5em 1em -1.5em;
        padding: 0 2em 1em 1.5em;

        .btn_ico {
            margin: 1em 0 0 0.25em;
        }
    }

    .intro {
        grid-column: span 3;
        height: 6.5em;
    }

    &.content ul {
        grid-column: span 3;
        margin: 0;

        li {
            margin-top: 1em;
        }
    }

    .crux {
        position: relative;
        padding-bottom: 1.5em;


        .cvw {
            border-radius: 0 1em 1em 0;
            background: #cccccc66;
            margin: 1em -2em 1em -1.5em;
            padding: 0 2em 1em;
        }

        &:before {
            content: "";
            position: absolute;
            left: -1.5em;
            right: -1em;
            top: 0em;
            bottom: 2px;
            border: 1px dotted var(--grey-medium);
            border-radius: 1em;
            z-index: -1;
        }

        .btn {
            margin: 1em 0 0 0;

        }
    }

    h4,
    h3 {
        grid-column: span 2;
        margin-bottom: 0;

    }

    h3 {
        font-size: 1em;
        color: var(--grey-dark);
    }

    .metric_name {
        position: relative;
        display: inline-block;
        padding-top: 1.75em;
        grid-column: 1/2;
        height: 1.85em;

    }

    .score_lght {
        position: relative;
        padding-bottom: 0.25em;

        .btn {
            margin: 1em 0 0 1em;

        }
    }

    &.nocrux .score_lght .cvw {
        border-radius: 1em;
    }

    .scalebar {
        --size: 10px;
        margin: 2.5em 0 1.5em;
        grid-column: 2/3;

        .value {
            font-size: 11px;
        }
    }

    .fid {
        opacity: 0;
    }
}

@media (min-width: $mq-2 ) {
    .metriques {


        .intro {

            height: 4.5em;
        }
    }
}

@media (min-width: $mq-3 ) {
    .metriques {
        &.content {
            font-size: 1em;

        }

        .intro {

            height: 3.5em;
        }



        .score_lght {
            .metric_name {
                margin-left: 2em;
            }

            &:before {
                content: "";
                position: absolute;
                width: 2.5em;
                left: -0.6em;
                height: 22.9em;
                top: 13.75em;
                border-left: 1px dotted var(--grey-medium);
                border-bottom: 1px dotted var(--grey-medium);
                border-radius: 0 0 0 1em;
            }

            &:after {
                content: "right";
                position: absolute;
                color: var(--grey-medium);
                left: -0.15em;
                top: 17.815em;
                font-family: a4i_ico;
                font-size: 2em;
            }


            .for_lght {
                &:before {
                    content: "";
                    position: absolute;
                    width: 2.4em;
                    left: -2.7em;
                    top: 2.5em;
                    border-top: 1px dotted var(--grey-medium);
                    ;
                }

            }

        }

        .metric_name {
            font-size: 0.9em;
        }

    }
}