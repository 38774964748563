form {
	small {
			font-style: italic;
		}
    label {

        &.block {
            display:block;
            line-height: 1.6em;
            color :var(--grey-medium);
        }
    }
	    input:not([type=radio]):not([type=checkbox]),
	    select,
    textarea {
        box-sizing: border-box;
        display: inline-block;
        height: calc(2.6em + 2px);
        padding: 0.6em;
        font-size: 1em;
        line-height: 1.4em;
        vertical-align: middle;
        background-color: #f7f7f7;
        border: 1px solid var(--grey-medium);
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        width: 45em;
        max-width : 100%;

        &:focus {
            border-color:var(--grey-verydark);
            background: #fff;
        }
    }
    textarea {
        resize: vertical;
        height:10em;
    }
    [type=checkbox],
    [type=radio] {
        transform: scale(1.25);
        vertical-align: text-top;
    }

}
