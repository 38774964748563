/* affiches des bonnes pratiques
sur une base details / summary */
.bp {
    position: relative;
    border-bottom: 1px solid #bfc0c1;
    font-size: 1em;
    line-height: 1.5em;




    summary {
        cursor: pointer;
        list-style: none;
        color: black;
        line-height: 1.2em;
        position: sticky;
        top: 50px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 100%);
        background-repeat: no-repeat;
        z-index: 1;
        padding: 1.125em 0 0 4.375em;

        &:after {
            content: "up";
            font-family: "a4i_ico";
            position: absolute;
            top: 1.4em;
            right: 0;
            transform: rotate(-180deg);
            font-size: 2em;
            color: var(--grey-dark);
            transition: all 0.25s ease-out;
        }

        .zone_ico {
            position: absolute;
            display: block;
            z-index: 3;

        }

        .comment {
            position: absolute;
            display: block;

            .ico {
                margin: -2.5em 0 0 0;
                position: relative;
                display: inline-block;
                left: -0.5em;
                color: var(--orange);
            }
        }

        //hide carte on safari iOS
        &::-webkit-details-marker {
            display: none;
        }
    }

    &[open] summary:after {

        transform: rotate(0deg);
    }

    .bp_num {
        font-weight: bold;
        display: inline-block;

        margin-right: 0.5em;
        color: var(--grey-medium);
        text-decoration: none;
    }

    .tag {
        display: inline-block;
        background: var(--grey-light);
        font-size: 0.6875em;
        vertical-align: text-bottom;
        font-weight: bold;
        color: var(--grey-dark);
        line-height: 1.65em;
        padding: 0 0.5em;

    }

    .bp_title {
        padding: 0.35em 1.5em 1.35em 0;
        margin: 0;
        font-size: 1.25em;
        display: block;

    }

    .bp_score {
        position: absolute;
        top: 0.9em;
        left: 0;
        font-size: 1.25em;

    }

    .bp_coef {
        display: none;
    }

    .metric {
        background: var(--grey-light);
        padding: 1.5em;
        border-radius: 1em;
        margin: 1.5em 0;

        .txt_warning {
            font-size: 0.8125em;
            line-height: 1.4em;
            color: var(--color_verybad);
            margin: 0 0 1em;
            font-weight: 600;
        }
    }

    .bp_metric_name {
        text-transform: uppercase;
        font-size: 0.875em;
        line-height: 1.2em;
        ;
        margin: 0 0 1em;
    }

    .bp_metric_explanation {
        font-size: 0.8125em;
        line-height: 1.4em;

        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    .bp_actions {
        font-size: 13px;

        .btn {
            margin: 0 8px 8px 0;
            vertical-align: text-top;
            border-radius: 4px;
            float: left;
            height: 40px;
            line-height: 27px;
            box-sizing: border-box;

            &.ico {
                font-size: 18px;
                padding: 0 0.5em
            }
        }

        &:after {
            content: '';
            display: block;
            clear: both;
            height: 0;

        }
    }

    .ref {
        font-size: 0.75em;
        color: var(--grey-medium);
        line-height: 1.5em;

    }

}

.bp.nan summary .bp_title {
    color: var(--grey-medium);
}

.priority .bp {
    order: var(--bp_priority);

}

.no_nan .bp.nan {
    display: none;
}

@keyframes headShake {
    0% {
        transform: translateX(0);
    }

    6.5% {
        transform: translateX(-6px) rotateY(-9deg);
    }

    18.5% {
        transform: translateX(5px) rotateY(7deg);
    }

    31.5% {
        transform: translateX(-3px) rotateY(-5deg);
    }

    43.5% {
        transform: translateX(2px) rotateY(3deg);
    }

    50% {
        transform: translateX(0);
    }
}

.bp summary:hover .comment {
    animation-timing-function: ease-in-out;
    animation-name: headShake;
    animation-duration: 0.5s;
    ;
}


@media (max-width: $mq-1 - 1px) {
    .metric {
        text-align: center;
    }

    .metric .txt_warning,
    .bp_metric_explanation {
        text-align: left;
    }

    .bp {
        summary {
            .zone_ico {
                left: -0.75em;
                top: 3.75em;
            }

            .comment {
                right: -1.5em;
                top: -0.5em;
                font-size: 0.75em;
            }
        }

    }


}

@media (min-width: $mq-1 ) and (max-width: $mq-3 - 1px) {
    .metric {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 2em;

    }

    .bp_metric_name {
        grid-column: 1 / end;
    }

    .txt_warning,
    .bp_metric_explanation {
        padding: 0 2em;
        grid-column: 2 / end;
    }

}

@media (min-width: $mq-1 ) {
    .bp {
        summary {
            .zone_ico {
                right: 3.5em;
                top: 2.5em;
            }

            .comment {
                right: 7.5em;
                top: .5em;
            }
        }
    }
}

@media (min-width: $mq-2 ) {
    .bp {
        grid-column: span 7;

        .bp_coef {

            position: absolute;
            font-size: .75em;
            color: var(--grey-medium);
            top: -3.5em;
            right: calc(100% + 5px);
            border: 1px dotted var(--grey-medium);
            border-top: none;
            border-right: none;
            line-height: 7.7em;
            width: 7em;
            text-align: right;
            height: 4.3em;
            padding: 3.25em 0 0;

            .hierarchy & {
                display: block;
            }

        }

        .grid {
            margin-left: 4.375em;
        }

        summary {
            top: 3.5em;


        }




    }

    .subcategory+.bp .bp_coef {
        border-left: none;
    }

    .hierarchy .bp:not(.lastofsub):after {
        content: "";
        display: block;
        position: absolute;
        border-right: 1px dotted var(--grey-medium);
        left: -5.65em;
        top: 3.15em;
        bottom: 2.5em;
    }
}

@media (min-width: $mq-3 ) {
    .bp {


        .bp_coef {
            width: 8.25em;
        }

        summary:after {
            right: 0.5em;
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.25em;
            padding-bottom: 1px;


        }

        .doc {
            grid-column: 1 / 3;
            grid-row: 1;
        }

        .ref {
            grid-column: 1 / 3;
            grid-row: 2;
            padding-bottom: 2.5em;
            align-self: self-end;
        }

        .metric {
            margin: 0 0 1em;
            padding: 1.25em 1.5em;
        }

        .aside {
            grid-column: 3;
            grid-row: 1/3;
            height: fit-content;
            position: sticky;
            top: 5em;
            z-index: 2;
            margin: 0;
            background: #fff;
            padding-bottom: 2.5em;
        }

    }

    .hierarchy .bp:not(.lastofsub):after {
        left: -6.55em;
    }

}

@media print {
    .bp {

        .metric {
            position: relative;
            top: 0;
            page-break-inside: avoid;

        }

        summary {
            page-break-inside: avoid;
        }

        summary:after {
            display: none;
        }
    }
}