.zoom_timestep {
    width: var(--big_fig_width);
    height: var(--big_fig_height);
    filter: none;
    opacity: 1 !important;
    transform: translateY(-40px);
}

.content.filmstrip {
    --bw_gradient: repeating-linear-gradient(45deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5) 6px,
            rgba(0, 0, 0, 0.05) 6px,
            rgba(0, 0, 0, 0.05) 12px);
    --longtask_gradient: repeating-linear-gradient(-45deg,
            rgba(247, 191, 191, 0.4),
            rgba(247, 191, 191, 0.4) 6px,
            rgba(247, 191, 191, 0.15) 6px,
            rgba(247, 191, 191, 0.15) 12px);

    position: relative;
    z-index: 1;
    gap: 1em 3em;

    .explication {
        font-size: 14px;
        line-height: 20px;
        margin: 2em 0 1em;
        position: relative;
        z-index: 50;
    }

    .encart {
        position: relative;
        z-index: 50;

        .explication {
            margin-bottom: 0;
        }
    }

    .legendes {
        position: relative;
        z-index: 50;
        font-size: 12px;
        columns: 2;

        .ico {
            margin: 0 0.2em 0 -1.3em;
        }

        .bw:before {
            background: var(--bw_gradient);
        }

        .longtask:before {
            background: var(--longtask_gradient);

        }

        .blocking_request:before {
            background: #db1111;
        }

    }

    .raccourcis {
        .ltag {
            background: #0a1521;
            text-decoration: none;
            color: var(--grey-light);
            border: none;
            cursor: pointer;
        }
    }
}

.desktop .timeline_container {
    --max_step_scale: 400px;
    --zoom_factor: 82px;
    --dec_fig: 40px;
}

.desktop .activity .timeline_container {
    --dec_fig: 210px;
}

.timeline_container {
    --max_step_scale: 178px;
    --fig-border: 4px;
    --timestep: 100;
    --dec_fig: 11px;
    --zoom_factor: 22px;
    //échelle de temps en largeur dépend du format de l'écran.
    --step_scale_cal: min(calc((100vh - 450px) * var(--vw) / var(--vh)), var(--max_step_scale));
    --step_scale: max(var(--step_scale_cal), 140px);

    //taille de base de la vue en grand
    --small_fig_width: calc(var(--step_scale) - (2 * var(--fig-border)) - 5px);
    --small_fig_height: calc(var(--small_fig_width) * var(--vh) / var(--vw) + 0px);

    //taille de base de la vue concise
    --big_fig_width: calc(var(--step_scale) - (2 * var(--fig-border)) - 5px + var(--zoom_factor));
    --big_fig_height: calc(var(--big_fig_width) * var(--vh) / var(--vw) + 0px);

    position: relative;
    width: 100%;
    height: calc(var(--big_fig_height) + 152px);
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0;
    padding: 1em 0 2em;
    border-bottom: 1px dotted var(--grey-medium);

    //on ne peut l'activer à cause du script qui tranforme le scroll vertical en horizontal
    //scroll-behavior: smooth;


    &::-webkit-scrollbar {
        width: 12px;
        background-color: #F5F5F5;
    }

    //kpi placés aux moments précis
    .kpi_pin {
        position: absolute;
        z-index: 10;
        padding: 4px;
        margin: 0;
        --leftdec: calc(var(--step_scale) / 2);
        --startpx: calc(((var(--value) / var(--timestep)) * var(--step_scale)) + var(--leftdec));
        left: var(--startpx);
        transform: translateX(-50%);
        top: 60px;

        font-size: 12px;
        line-height: 1em;
        font-weight: 400;
        text-align: center;

        min-width: 50px;
        border-radius: 4px;

        background: #0a1521;
        color: var(--grey-light);
        box-shadow: 0 0 1px 1px #fff;

        transition: all 0.25s ease-in;
        scroll-margin: 0 var(--step_scale);

        .value {
            display: block;
            font-size: 14px;
            font-weight: 600;
        }

        &:before {

            content: "";
            position: absolute;
            top: 100%;
            left: calc(50% - 8px);
            width: 0;
            height: 0;
            border-color: #0a1521 transparent transparent transparent;
            border-style: solid;
            border-width: 8px;

        }

        &.lcp {
            cursor: zoom-in;
            z-index: 11;
        }

        &:focus {
            outline: 5px double #0a1521;
            z-index: 12;
        }

    }

    //cas des cls
    .cls {
        position: absolute;
        bottom: -20px;
        --startpx: calc(((round(up, var(--start), var(--timestep)) / var(--timestep)) * var(--step_scale)));
        left: var(--startpx);
        min-width: 100px;
        background: var(--bg);
        color: var(--color);
        border-radius: 4px;
        z-index: 10;
        text-align: center;
        padding: 4px;
        transform: translateX(-50%);
        cursor: zoom-in;

        small {
            display: block;
        }

        &.cls_good {
            --bg: var(--color_good);
            --color: #fff;
        }

        &.cls_bof {
            --bg: var(--color_bof);
            --color: #333;
        }

        &.cls_bad {
            --bg: var(--color_bad);
            --color: #fff;
        }

        &:focus {
            outline: 5px double var(--bg);
            z-index: 12;
        }

    }

    //elements finaux de timeline
    .longtask {
        position: absolute;
        z-index: 1;
        top: -1em;
        bottom: -2em;
        --leftdec: calc(var(--step_scale) / 2);
        --startpx: calc(((var(--start) / var(--timestep)) * var(--step_scale)) + var(--leftdec));
        --endpx: calc(((var(--end) / var(--timestep)) * var(--step_scale)) + var(--leftdec));
        --width: calc(var(--endpx) - var(--startpx));
        left: var(--startpx);
        width: var(--width);
        background: var(--longtask_gradient);
        border-radius: var(--fig-border);
        transition: all 0.25s ease-in;
    }

    .timeline {


        position: relative;
        z-index: 2;
        list-style-type: none;
        margin: 0 50px 0 20px;
        padding: 0;
        display: grid;
        grid-auto-flow: column;
        white-space: nowrap;

        p {
            margin: 0;
        }

        .fcp {
            --border-color: #28BC00;
        }

        .lcp {
            --border-color: #1c7703;
        }

        //interaction sur les kpis
        .timestep:has(+.cls:hover),
        .cls:hover+.timestep {
            z-index: 7;

            .cls_zone {
                opacity: 1;
                transition-delay: .25s;
                animation: animateBg 12s linear infinite alternate;
            }

            figure {
                @extend .zoom_timestep;
            }

            .key_numbers,
            .cpu,
            .bw {
                opacity: 0
            }


            &+.kpi_pin {
                top: 20px;
            }
        }



        .kpi_pin.lcp:hover~.keyframe.postlcp,
        .cls:hover+.timestep {
            figure {
                transform: translate(var(--dec_fig), -40px);
            }
        }


        .keyframe.prelcp:has(~.kpi_pin.lcp:hover),
        .timestep:has(+.cls:hover) {
            figure {
                transform: translate(calc(var(--dec_fig) * -1), -40px);
            }
        }

        .kpi_pin.lcp:hover~.keyframe.postlcp,
        .keyframe.prelcp:has(~.kpi_pin.lcp:hover) {

            z-index: 7;

            .cpu,
            .bw {
                opacity: 0;
            }

            .lcp_zone {
                opacity: 1;
                transition-delay: .25s;
                animation: animateBg 12s linear infinite alternate;
            }

        }

        @keyframes animateBg {
            100% {
                background-position: left;
            }
        }
    }

    //1er element de l'ol timeline
    .connection {
        position: absolute;
        top: 60px;
        height: 32px;
        left: calc(var(--step_scale) / 2);
        display: table;
        font-size: 12px;
        border-radius: 4px;
        overflow: hidden;
        transition: left 0.25s ease-in;

        .dns,
        .init,
        .ssl {
            display: inline-block;
            height: 100%;
            width: calc(var(--value) / var(--timestep) * var(--step_scale));
            padding: 4px 6px;
            line-height: 1em;
            font-weight: 600;
            opacity: 0.85;
            transition: width 0.25s ease-in;

            &:before {
                font-weight: 400;
                display: block;
            }
        }

        .dns {
            background: #4ca2a8;

            &:before {
                content: "DNS";
            }
        }

        .init {
            background: #ffa24c;

            &:before {
                content: "INIT";
            }
        }

        .ssl {
            background: #dd66e8;

            &:before {
                content: "SSL";
            }
        }

    }

    //element de base de l'ol timeline
    .timestep {
        --border-color: #eee;
        --bar_height: calc(var(--small_fig_height) + (2*var(--fig-border)) + 2px);
        position: relative;
        z-index: 5;
        text-align: center;

        // block time+ border de figure + margin : 18px + 12px + 40px + 60px
        height: calc(var(--big_fig_height) + 130px);
        width: var(--step_scale);
        vertical-align: top;
        display: inline-block;
        white-space: normal;

        transition: all 0.25s ease-in;

        &:before {
            content: "";
            position: absolute;
            width: 1px;
            top: 1em;
            bottom: 0;
            border-left: 1px dotted #999;
            left: 50%;
            z-index: 1;
        }

        &.end {
            width: var(--max_step_scale) !important;

            &:before {
                content: none;
            }
        }

        time {
            display: block;
            font-weight: 600;
            color: #666;
        }

        figure {
            position: relative;
            width: var(--small_fig_width);
            height: var(--small_fig_height);
            z-index: 2;
            border: var(--fig-border) solid var(--border-color);
            box-shadow: 0 0 2px #777;
            border-radius: var(--fig-border);
            margin: 80px calc(var(--zoom_factor) / -2) 0;
            display: inline-block;
            filter: grayscale(1);
            opacity: 0.65;
            transition: all 0.25s ease-in;
            overflow: hidden;


            img {
                display: block;
                height: 100%;
                width: auto;
            }

            .lcp_zone,
            .cls_zone {
                position: absolute;

                left: calc(var(--x) * 100% / var(--vw));
                top: calc(var(--y) * 100% / var(--vh));
                width: calc(var(--w) * 100% / var(--vw));
                height: calc(var(--h) * 100% / var(--vh));

                opacity: 0;
                transition: all 0.25s ease-in 0s;

                background: repeating-linear-gradient(45deg,
                        var(--bgcolor),
                        var(--bgcolor) 6px,
                        rgba(0, 0, 0, 0.005) 6px,
                        rgba(0, 0, 0, 0.005) 12px);
                background-size: 200%;
                background-position: right;
                background-attachment: fixed;
            }

            .cls_zone {
                background-color: rgba(232, 87, 87, .25);
                --bgcolor: rgba(232, 87, 87, .25);
            }

            .lcp_zone {
                background-color: rgba(87, 232, 87, 0.35);
                --bgcolor: rgba(87, 232, 87, 0.35);
            }
        }

        .vertical_bar {
            height: calc(var(--value)* 1%);
            width: 100%;
            background: var(--bg1);
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            border-radius: 4px;
            display: grid;
            position: absolute;
            bottom: 0;
            align-items: end;
            padding-bottom: 4px;
            box-sizing: border-box;
        }

        .cpu {
            position: absolute;
            right: 20px;
            bottom: calc(72px - 2* var(--fig-border));
            width: 30px;
            height: var(--bar_height);
            font-weight: 600;
            z-index: 4;
            opacity: 0.85;
            border-radius: 4px;
            text-align: center;
            transition: all 0.25s ease-in;
            ;

            &:after {
                position: absolute;
                bottom: -16px;
                left: 0;
                content: "CPU";
                font-size: 14px;
                letter-spacing: -1px;
            }
        }

        .bw {

            position: absolute;
            right: 0px;
            bottom: calc(72px - 2* var(--fig-border));
            width: 16px;
            height: calc(var(--value) * var(--bar_height) / var(--max_bw));
            max-height: var(--bar_height);
            font-size: 12px;
            z-index: 4;
            opacity: 0.65;
            background: var(--bw_gradient);
            border-radius: 4px;
            text-align: center;
            transition: all 0.25s ease-in;

            &:after {
                position: absolute;
                bottom: -16px;
                left: 0;
                content: "BW";
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -1px;
            }
        }

        .visual_progress {
            display: none;
            margin: 0 0;
            position: absolute;
            bottom: 1.5em;
            text-align: center;
            width: 100%;
        }

        .key_numbers {
            position: absolute;
            z-index: 4;
            right: 56px;
            bottom: calc(58px - 2* var(--fig-border));
            text-align: right;
            font-size: 14px;
            transition: all 0.25s ease-in;
            opacity: 1;
            white-space: nowrap;
        }

        &.keyframe {
            z-index: 6;

            figure {
                @extend .zoom_timestep;

            }

            .visual_progress {
                font-weight: 600;
                display: block;
            }
        }

        &:hover {
            z-index: 7;
        }

        &:has(>figure:hover),
        &.keyframe {

            &+.kpi_pin,
            &+.kpi_pin+.kpi_pin,
            &+.kpi_pin+.kpi_pin+.kpi_pin {
                top: 20px;
            }


        }

    }

    .filmstrip:not(.activity) & {
        .timestep figure:hover {
            @extend .zoom_timestep;

            &~.cpu,
            &~.bw,
            &~.key_numbers {
                opacity: 0;
            }

            &+.visual_progress {
                display: block;

            }
        }
    }

    .filmstrip.activity & {
        --dec_fig: 69px;

        .timestep:has(+.cls:hover),
        .kpi_pin.lcp:hover~.keyframe.postlcp,
        .keyframe.prelcp:has(~.kpi_pin.lcp:hover) {
            figure {
                @extend .zoom_timestep;
            }
        }

        .timestep {
            --bar_height: calc(var(--big_fig_height) / 2);
            width: 60px;

            time {
                font-size: 12px;
                line-height: 17px;
                font-weight: 400;
            }

            figure {
                margin: 80px calc(var(--zoom_factor) * -2.55) 0;
                width: 90px;
                height: calc(90px* var(--vh) / var(--vw) + 0px);
                transform: translateY(-40px);
            }

            &.keyframe {
                time {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            &.keyframe,
            &.even {


                .visual_progress {
                    display: none;
                }

                figure:hover {
                    @extend .zoom_timestep;

                    &~.cpu,
                    &~.bw,
                    &~.key_numbers {
                        opacity: 0;
                    }


                    &+.visual_progress {
                        display: block;

                    }
                }


            }

            &.even:not(.keyframe) figure {
                opacity: 0.3;
            }

            &.odd:not(.keyframe) figure {
                opacity: 0;
            }

            .key_numbers {
                right: 50%;
                transform: translateX(50%);
                bottom: calc(32px - 2*var(--fig-border));
                font-size: 11px;

                .blocking_request {
                    display: none;
                }

                .requests:has(>.blocking_request) .ico {
                    color: #fff;
                    background: #db1111
                }


            }

            .cpu {
                right: 32px;
            }

            .bw {
                right: 11px;
            }

        }

        .kpi_pin,
        .connection {
            top: 20px !important;
        }

        .cls,
        .connection,
        .longtask,
        .kpi_pin {
            --step_scale: 60px;
        }
    }

    #emoji {
        position: fixed;
        left: 50%;
        top: calc(var(--big_fig_height) + 340px);
        width: 50px;
        height: auto;
        z-index: 20;
        background: radial-gradient(#fff, rgba(255, 255, 255, 0));
        animation: shakeX 3s forwards;
    }

    @keyframes shakeX {

        from,
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        5%,
        25%,
        45%,
        65%,
        85% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
            opacity: 1;
            z-index: 20;
        }

        15%,
        35%,
        55%,
        75% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }

        100% {
            opacity: 0;
            z-index: 1;
        }
    }

}

[for="only_keyframe"] {
    position: relative;
    float: right;
}



.requests,
.access_dom {
    --ico_size: 18px;
    display: inline-block;
    position: relative;
    font-weight: 600;
    border-radius: 0.285em;
    background: #ddd;
    line-height: 1.8em;
    vertical-align: middle;
    padding: 0 0.5em;
    margin: 0;

    .ico {
        position: absolute;
        left: calc(var(--ico_size) / -2);
        bottom: calc(var(--ico_size) / -2);
        font-size: 1.2em;
        border-radius: 50%;
        color: #444;
        z-index: 2;
        background: #ffffffbb;
        height: var(--ico_size);
        width: var(--ico_size);
        line-height: var(--ico_size);
        text-align: center;

    }
}

.access_dom {
    .ico {
        font-size: 1em;
    }
}

.requests {
    .blocking_request {
        display: inline-block;
        position: relative;
        z-index: 1;
        border-radius: 4px 0 0 4px;
        background: #db1111;
        line-height: 1.8em;
        padding: 0 .5em;
        margin: -.5em 0.15em 0 -.5em;
        color: #fff;
    }
}

.kpi_ls {

    position: relative;
    font-size: 12px;
    height: var(--bar_height, 18px);
    width: var(--bar_width, 100%);
    margin: 0;
    padding: 0;
    display: inline-flex;
    vertical-align: middle;

    .ls {

        display: inline-block;
        --wls: calc(var(--value) * 100% / var(--total_cls));
        width: calc(Round(up, var(--wls), 1%));
        min-width: 3px;
        cursor: pointer !important;

        text-align: center;

        &:before {
            content: "";
            border-radius: 2px;
            background: #ddd;
            width: calc(100% - 2px);
            height: 100%;
            display: inline-block;

        }
    }

    .past.cls_good:before {
        background: var(--color_good);
    }

    .past.cls_bof:before {
        background: var(--color_bof);
    }

    .past.cls_bad:before {
        background: var(--color_bad);
    }

    &+p {
        margin: 0.25em 0 0 0;
    }

}


.content.video {
    --bw_gradient: repeating-linear-gradient(45deg,
            rgba(0, 0, 0, 0.15),
            rgba(0, 0, 0, 0.15) 6px,
            rgba(0, 0, 0, 0.025) 6px,
            rgba(0, 0, 0, 0.025) 12px);
    --longtask_gradient: repeating-linear-gradient(-45deg,
            rgba(247, 191, 191, 0.8),
            rgba(247, 191, 191, 0.8) 6px,
            rgba(247, 191, 191, 0.5) 6px,
            rgba(247, 191, 191, 0.5) 12px);


    .footer_legendes {
        grid-column: span 2;
        grid-row-start: 4;
        margin-bottom: -10em;
        padding: 0;
        border-top: 1px dotted var(--grey-medium);

        .legendes {
            columns: 3;
            font-size: 12px;
            line-height: 14px;

            .ico {
                margin: 0 .2em 0 -1.3em;
            }
        }

        .bw:before {
            background: var(--bw_gradient);
        }

        .longtask:before {
            background: var(--longtask_gradient);

        }

        .blocking_request:before {
            background: #db1111;

        }
    }

    video {
        max-width: 100%;
    }

    .visuel {
        grid-row-start: 1;
        grid-column-start: 2;
        grid-row-end: 4;
    }

    .track {
        --height: 456px;
        display: inline-block;
        height: var(--height);
        width: calc(var(--height) * var(--vw) / var(--vh));

        border: 6px solid #eee;
        box-shadow: 0 0 2px #777;
        border-radius: 6px;

        position: relative;


        //--currentStep:
        img {
            //--step
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: auto;
            opacity: calc(Round(down, var(--currentStep)/var(--step)));


            transition: all .1s ease-in 0s;
        }

        .big_play {
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            font-size: 3em;
            border-radius: 50%;
            height: 1.5em;
            width: 1.5em;
            line-height: 1.25em;
            text-align: center;
            background: #eee;
            color: var(--grey-medium);
            border: none;
            cursor: pointer;

            &:hover {
                background: #ccc;
                color: #fff;

            }

        }
    }

    &.neverplaying {
        .big_play {
            display: block
        }
    }

    ol {
        padding: 0;
        list-style: none;
        margin: 0;
    }

    h3 {
        font-size: 1em;
        margin: 0 0 4px;
    }

    .bigvalue {
        font-size: 1.5em;
        line-height: 1.5em;
        margin: 0;
    }

    .sep {
        margin-top: 1.5em;
    }

    .kpis {
        grid-column-start: 1;
        text-align: right;
        border-bottom: 1px dotted var(--grey-medium);
        margin-bottom: 0.5em;

        &.visualprog {
            margin-top: 3.2em;
        }

        &.layoutshift {
            --bar_height: 30px;
        }

        &.metrics {

            border-bottom: none;
            padding-bottom: 2.2em;
        }


    }

    .kpi_pin {
        --bg: #f2f2f2;
        position: relative;
        margin: 0 0 0.5em;
        font-size: 14px;
        padding: 8px;
        min-width: 50px;
        line-height: 1.4em;
        list-style: none;
        border-radius: 4px;
        text-align: center;
        background: var(--bg);
        color: #888;
        transition: all .1s ease-in 0s;
        cursor: pointer;

        .value {
            display: block;
            font-size: 18px;
            font-weight: 600;
        }

        &:before {
            content: "";
            position: absolute;
            left: 90%;
            top: calc(50% - 8px);
            width: 0;
            height: 0;
            border-color: transparent transparent transparent var(--bg);
            border-style: solid;
            border-width: 8px;
            opacity: 0;
            transition: all .1s ease-in 0s;
        }

        &.active:before {
            left: 100%;
            opacity: 1;

        }

        &.active,
        &:hover {
            --bg: #0a1521;
            color: var(--grey-light);
        }
    }








    .controls {
        --color_ctrl: var(--grey-dark);
        --bkg_ctrl: var(--grey-light);
        margin: 0 0 1em;
        padding: 0;
        text-align: left;

        #currentstep {

            --position: calc(var(--currentStep) * (100% - 7px) / var(--maxStep));
            width: 100%;
            -webkit-appearance: none;
            background: linear-gradient(to right, var(--color_ctrl) 0%, var(--color_ctrl) var(--position), var(--bkg_ctrl) var(--position), var(--bkg_ctrl) 100%);
            border: none;
            border-radius: 3px;
            height: 6px;
            margin: 0 0 11px 0;

            &::-webkit-slider-thumb {
                background-color: var(--color_ctrl);
                height: 14px;
                width: 14px;
                border-radius: 50%;
                -webkit-appearance: none;
            }
        }

        .btn {
            color: var(--color_ctrl);
            font-size: 16px;
        }

        .pause {
            display: none;
        }

        .speed_ctrl {
            position: relative;
            white-space-collapse: collapse;
            display: flex;
            float: right;

            input {
                position: absolute;
                top: 0;
                height: 0;
                z-index: 0;
                visibility: hidden;

                &:checked+label {
                    background: #cdcdcd;
                    color: #333;
                }
            }

            label {
                color: var(--color_ctrl);
                background: var(--bkg_ctrl);
                font-size: 14px;
                line-height: 14px;
                padding: 9px;
                cursor: pointer;
                font-weight: 400;
                display: inline-block;
                vertical-align: middle;

                &:first-of-type {
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }

                &:hover {
                    background: var(--grey-medium);
                }
            }


        }
    }

    &.playing {
        .controls {
            .play {
                display: none;
            }

            .pause {
                display: inline-block;
            }
        }

        .bar {
            transition: all var(--transitiontiming) ease-in 0s;
        }



    }

    .data_by_step {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-row-end: 5;

        display: grid;
        margin-bottom: -10em;
        grid-template-columns: 7em auto;
        padding: 3.2em 0 10em;
        align-content: baseline;
        gap: 0 1.5em;

        .js_exec {
            grid-column: span 2;
        }

        .bigvalue {
            background: #f2f2f2;
            border-radius: 4px;
            display: block;
            text-align: center;
            padding: 0;

            .ico {
                font-size: 0.75em
            }

            &.access_dom .ico {
                font-size: 0.6em
            }

            .blocking_request {
                line-height: inherit;
                float: left;
                margin: 0;
                min-width: 50%;
                padding: 0;
            }
        }

        .ltag {
            margin-top: -4px;
        }

        p {

            margin: 0 0 14px
        }



        .bar.bw {
            max-width: 100%;
            height: 2.25em;
            background: var(--bw_gradient);
            font-weight: 600;
            line-height: 2.3em;
            font-size: 1em;
            text-indent: 0.5em;
            color: #333;
            text-shadow: 0 0 2px #fff;
        }

        .bar.cpu {
            max-width: 100%;
            height: 2.25em;
            color: #ededed;
            font-weight: 600;
            line-height: 2.3em;
            font-size: 1em;
            text-indent: 0.5em;
        }

        .js_exec {
            position: relative;

            ol {
                --bar_width: 8em;
                --max: 100;
                --value1: 0;
                position: relative;
                z-index: 2;
                font-size: 14px;
                list-style: none;
                margin: 0;
                padding: 0;
                display: grid;

                &+p {
                    position: absolute;
                    top: 1.6em;
                    opacity: 1;
                    transition: opacity .250s ease-in .350s;
                    z-index: 1;
                }

                &.active+p {
                    opacity: 0;
                    transition: opacity .10s ease-in 0s;
                }
            }


            li {
                --transition_js: calc(var(--transitiontiming) / 2);
                display: grid;
                grid-template-columns: 9.5em 2em auto;
                gap: 0 0.25em;
                line-height: 26px;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                //transition: opacity var(--transition_js) ease-in 0s, max-height .1s ease-in var(--transition_js);
                transition: all var(--transition_js) ease-in 0s;
                order: calc(var(--value1) * -1);
            }

            li.active {
                opacity: 1;
                max-height: 57px;
                transition: opacity var(--transition_js) ease-in 0s, max-height .1s ease-in .0s;
            }

            .bar {
                font-size: 14px;
                display: inline-block;
                background: #ddd;
                max-width: 8em;
                transition: width 200ms ease-in 0s;
            }

            .blocking .bar {
                background: var(--longtask_gradient);
            }


            a {
                color: inherit;
                text-decoration-style: dotted;
                text-underline-position: under;
                text-decoration-color: #888;

            }

        }

    }

    [for="openclose_encart"] {
        position: absolute;
        bottom: .25em;
        right: .25em;
        cursor: pointer;

        .ico {
            display: block;
            border-radius: 50%;
            height: 1.5em;
            width: 1.5em;
            line-height: 1.5em;
            text-align: center;
            background: #eee;
            color: var(--grey-medium);


            &:hover {
                background: #ddd;
                color: #000;

            }
        }


        .ico:last-child {
            display: none;
        }
    }

    .encart {
        position: sticky;
        z-index: 10;
        grid-column-start: 3;
        bottom: -0.5em;
        max-width: 0;
        height: 10em;
        background: transparent;
        justify-self: end;
        overflow: hidden;
        transition: all 250ms ease-in 150ms;

        .explication {
            opacity: 0;
            transition: all 150ms ease-in 0ms;
        }

    }

    #openclose_encart:checked+.encart {
        max-width: 100%;

        background: #eee;
        transition-delay: 0ms;

        .explication {
            opacity: 1;
            transition-delay: 250ms;
        }

        [for="openclose_encart"] {

            .ico:first-child {
                display: none;
            }

            .ico:last-child {
                display: block;
            }
        }
    }



}

.desktop {
    .content.video {


        .kpis {
            position: relative;
            grid-column-start: auto;
            text-align: left;
            border: none;
            margin: 1em 0 0 !important;
            padding: 0 !important;

            &.visualprog {
                margin: 0;
            }

            &.layoutshift {
                grid-column-start: 3;
                --bar_height: 44px;



                p {
                    position: absolute;
                    right: 0;
                    bottom: -12px;
                }
            }

            &.metrics {
                grid-column-start: 2;
                grid-row-start: 1;

                ol {
                    display: grid;
                    grid-auto-flow: column;


                }
            }
        }

        .kpi_pin {
            padding: 8px 0;
            margin-right: 2px;
            font-size: 12px;
            line-height: 14px;

            .value {
                font-size: 14px;
            }

            &:before {
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                border-color: #0a1521 transparent transparent transparent;
            }
        }


        .visuel {
            position: relative;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 3;

            p:first-child {
                position: absolute;
                bottom: 5px;
                left: 80px;
            }
        }

        .track {
            --height: 296px;
        }



        .data_by_step {
            grid-row-start: 2;
            grid-row-end: 4;
            grid-column-start: 3;
            padding: 0 0 13em;
        }

        .footer_legendes {
            grid-row-start: 3;
            grid-row-end: 5;
            margin: 0
        }

        .encart {
            grid-column-start: 3;
            grid-row-start: 4;
            height: 13em;
        }

    }
}

@media (min-width: $mq-1 ) {
    .content.filmstrip {
        .legendes {
            columns: 3;

            .longtask {
                white-space: nowrap;
            }
        }
    }

    .timeline_container {

        &:after,
        &:before {
            content: "";
            position: fixed;
            top: 0;
            bottom: 0;
            width: 2em;
            height: 100%;
        }

        &:before {
            left: 30px;
            z-index: 3;
            background: linear-gradient(90deg, #fff, #fff 50%, hsla(0, 0%, 100%, 0));
        }

        &:after {
            right: 30px;
            z-index: 32;
            background: linear-gradient(-90deg, #fff, #fff 50%, hsla(0, 0%, 100%, 0));
        }
    }


}

@media (min-width: $mq-3 ) {
    .content.filmstrip {
        .legendes {
            columns: 4;


        }
    }


    .content.video {
        display: grid;
        grid-template-columns: 15% 260px auto;
        gap: 0 3em;
    }

    .desktop {
        .content.video {
            grid-template-columns: 13% auto 50%;
            gap: 1em 2em;
        }
    }
}