/* composants graphiques utilisés dans les popins */

/* tag ou étiquette */
.ltag {

    border-radius: 4px;
    padding: 0 4px;
    font-size: 11px;
    font-weight: 700;
    display: inline-block;
    line-height: 18px;
    min-height: 18px;
    min-width: 24px;
    text-align: center;
    color: #fff;
    position: relative;
    background: var(--tagcolor);
    box-sizing: border-box;
    vertical-align: middle;

}

.tag_pre_good,
.tag_pre_bad,
.tag_preconnect,
.tag_prefetch,
.tag_pre,
.tag_nc,
.tag_nc_1ko,
.tag_br,
.tag_gz {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -6px;
        width: 0;
        height: 0;
        border-color: transparent transparent transparent var(--tagcolor);
        border-style: solid;
        border-width: 9px 0 9px 8px;

    }
}

.tag_pre_bad,
.tag_preconnect,
.tag_prefetch,
.tag_pre {
    margin-right: 3px;
}


/* bar horizontal à 2, 3 ou 4 secteurs */
.bar {
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    height: 1.64em;
    line-height: 1.64em;
    font-size: 11px;
    color: #000;
    text-indent: 3px;
    box-sizing: border-box;
    white-space: nowrap;

    &.sectors_1 {

        width: calc(var(--value1) / var(--max) * var(--bar_width));
        background: var(--bg1);
    }

    &.sectors_2 {
        --sector1: calc(var(--value1) / var(--max) * var(--bar_width));
        --sector2: calc(var(--value2) / var(--max) * var(--bar_width));

        width: var(--sector2);

        background-image: linear-gradient(90deg,
                var(--bg1) var(--sector1),
                var(--bg2) var(--sector1),
                var(--bg2) var(--sector2));
    }

    &.sectors_3 {
        --sector1: calc(var(--value1) / var(--max) * var(--bar_width));
        --sector2: calc(var(--sector1) + (var(--value2) / var(--max) * var(--bar_width)));
        --sector3: calc(var(--sector2) + (var(--value3) / var(--max) * var(--bar_width)));

        width: var(--sector3);

        background-image: linear-gradient(90deg,
                var(--bg1) var(--sector1),
                var(--bg2) var(--sector1),
                var(--bg2) var(--sector2),
                var(--bg3) var(--sector2),
                var(--bg3) var(--sector3));
    }


    &.sectors_4 {
        --sector1: calc(var(--value1) / var(--max) * var(--bar_width));
        --sector2: calc(var(--sector1) + (var(--value2) / var(--max) * var(--bar_width)));
        --sector3: calc(var(--sector2) + (var(--value3) / var(--max) * var(--bar_width)));
        --sector4: calc(var(--sector3) + (var(--value4) / var(--max) * var(--bar_width)));

        width: var(--sector4);
        background-image: linear-gradient(90deg,
                var(--bg1) var(--sector1),
                var(--bg2) var(--sector1),
                var(--bg2) var(--sector2),
                var(--bg3) var(--sector2),
                var(--bg3) var(--sector3),
                var(--bg4) var(--sector3),
                var(--bg4) var(--sector4));
    }

    &.sectors_5 {
        --sector1: calc(var(--value1) / var(--max) * var(--bar_width));
        --sector2: calc(var(--sector1) + (var(--value2) / var(--max) * var(--bar_width)));
        --sector3: calc(var(--sector2) + (var(--value3) / var(--max) * var(--bar_width)));
        --sector4: calc(var(--sector3) + (var(--value4) / var(--max) * var(--bar_width)));
        --sector5: calc(var(--sector4) + (var(--value5) / var(--max) * var(--bar_width)));

        width: var(--sector5);
        background-image: linear-gradient(90deg,
                var(--bg1) var(--sector1),
                var(--bg2) var(--sector1),
                var(--bg2) var(--sector2),
                var(--bg3) var(--sector2),
                var(--bg3) var(--sector3),
                var(--bg4) var(--sector3),
                var(--bg4) var(--sector4),
                var(--bg5) var(--sector4),
                var(--bg5) var(--sector5));
    }

    &.txtout {
        text-indent: calc(100% + 3px);
    }

}

/* donuts */
.donuts {
    --radial: radial-gradient(#fff 53.5%, transparent 55%);
    --sector1: calc(var(--val1) / var(--max) * 100);
    --text_color: #000;

    display: flex;
    flex-direction: column;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    color: #000;
    font-size: 1.625em;
    align-items: center;
    justify-content: center;

    &.part2 {

        background: var(--radial),
            conic-gradient(var(--bg1) calc(var(--sector1) * 1%),
                var(--bg2) 0);
    }

    &.part3 {

        background: var(--radial),
            conic-gradient(var(--bg1) calc(var(--val1) * 1%),
                var(--bg2) calc(var(--val1) * 1%),
                var(--bg2) calc(var(--val2) * 1%),
                var(--bg3) 0);
    }

    &.part4 {

        background: var(--radial),
            conic-gradient(var(--bg1) calc(var(--val1) * 1%),
                var(--bg2) calc(var(--val1) * 1%),
                var(--bg2) calc(var(--val2) * 1%),
                var(--bg3) calc(var(--val2) * 1%),
                var(--bg3) calc(var(--val3) * 1%),
                var(--bg4) 0);
    }

    small {
        font-size: .6em;
    }

    .value {
        display: block;
        font-weight: 700;
        color: var(--bg2);

        &.alone {
            font-size: 1.25em
        }
    }

    .precision {
        display: block;
        width: 50%;
        text-align: center;
        border-top: 1px dotted #999;
        font-size: .6em;
        color: var(--bg1);
        padding-top: 0.25em;
        font-weight: bold;
    }

    &.inversevalue {
        .value {
            color: var(--bg1);
        }

        .precision {
            color: var(--bg2);
        }
    }
}

/* molécule graph_donuts avec donuts */
.graph_donuts {
    display: grid;
    grid-template-columns: 58% auto;
    gap: 1.5em;
    margin-bottom: 2em;

    &.center {
        grid-template-columns: none;
        text-align: center;
        margin-bottom: 1em;
        gap: 0.8em;

        .donuts {
            margin: 0 auto;
        }

        .legendes_gbb {
            li {
                display: inline-block;
            }
        }
    }

    .donuts {
        margin: 0 0 0 2em;
    }

    .legende {
        font-size: 0.75em;
        line-height: 1.1em;
        margin: 0;

        .value {
            display: block;
            font-weight: bold;
            font-size: 1.15em;
        }

        .ico {
            font-size: 1.75em;
            float: left;
            margin: -.1em .1em 0 0;
        }

        &:before {
            content: "";
            display: inline-block;
            height: 1.5em;
            width: 1.5em;
            background: var(--bg1);
            border-radius: 4px;
            margin: -0.1em 0.5em 0 0.15em;
            float: left;

        }
    }



    .donuts {
        grid-row: span 2;

    }

    .reperes {
        align-self: end;
    }

}

.legendes_gbb {
    --bg1: var(--color_good);
    --bg2: var(--color_bof);
    --bg3: var(--color_bad);

    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;

    li:before {
        content: "";
        display: inline-block;
        height: 1.5em;
        width: 1.5em;
        background: var(--bg1);
        border-radius: 4px;
        margin: 0em .5em 0.5em .15em;
        vertical-align: middle;
    }

    li:nth-child(2):before {
        background: var(--bg2);
    }

    li:nth-child(3):before {
        background: var(--bg3);
    }
}

/* reperes sur les molécules */
.reperes {
    color: #555;
    vertical-align: bottom;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.75em;
    line-height: 1.1em;


    li {
        margin: 0.75em 0 -0.1em;
    }

    .ico {
        font-size: 1.75em;
        float: left;
        margin: -.1em .25em 0 0;
    }

    .value {
        display: block;
        font-weight: bold;
        font-size: 1.15em;

    }


}

/* molécule graph_bar avec bar */
.graph_bar {
    position: relative;

    .bar {
        font-size: 1.85em;
        --bar_width: 100%;
    }

    &+.legendes+.reperes,
    &+.reperes {
        float: right;
        margin: 2em 0 0;

        li {
            margin: 0em 0 -0.1em 1.25em;
            display: inline-block;
            white-space: nowrap;
        }
    }

    &+.legendes+.reperes {
        margin: 1em 0 0;
    }

    .indicateur {
        position: absolute;
        top: 3em;
        left: 0.5em;
        width: 100%;
        color: #eee;
        text-shadow: 0px 0px 6px #333;
    }
}

/* legendes */
.legende {
    display: inline-block;
    margin-right: 2em;
    font-size: 1em;
    line-height: 1.25em;

    strong {
        font-size: 1.25em;

    }

    &:before {
        content: "";
        display: inline-block;
        height: 1.5em;
        width: 1.5em;
        background: var(--bg1);
        border-radius: 4px;
        margin: -.1em .25em -0.25em .15em;
        float: left;

    }
}

.legendes {
    margin: 1em 0;
    padding: 0;
    color: #444;
    font-size: 13px;
    line-height: 18px;
    columns: 2;
    column-gap: 2em;

    &.short {
        float: left;
        columns: 3;
    }


    &.large {
        font-size: 11px;
        line-height: 14px;
        columns: unset;
        min-height: 38px;

        &>li {
            float: left;
            width: 12em;
        }
    }

    &>li {
        list-style: none;
        padding: 0 0 0.5em 2em;
        margin: 0;


    }

    li:not(:last-child) {
        padding-bottom: 0.5em;
    }

    &>li .legende {
        font-weight: 700;
        margin: 0 0 0 -2.15em;
    }

    .group {
        &>.legende {
            text-transform: uppercase;
            padding-bottom: 0.25em;
        }


    }



    p {
        margin: 0 0 0.5em;
    }

    ul {
        list-style: none;

        padding: 0;
    }




}



/* indicateur seul sans graphique */
.indicateur {
    display: grid;
    grid-template-columns: 64% auto;
    gap: 2em;


    &.large {
        grid-template-columns: 40% auto;

        &>.reperes {
            text-align: right;

            li {
                margin: 0em 1em -0.1em 1.25em;
                display: inline-block;
                white-space: nowrap;
                min-width: 8em;
                text-align: left;
            }
        }
    }

    &.fleft {
        float: left;
        margin: 0 1em 1em;
        white-space: nowrap;
    }

    strong {
        font-size: 2.2em;
    }

    .lbl {
        display: inline-block;
        text-transform: uppercase;
        font-size: 0.875em;
        line-height: 1em;
    }

    p {
        margin: -.35em 0 0;
    }

    &>.reperes {
        margin: 0.1em 0 0;
        display: inline-block;

        li {
            margin: 0;
        }


    }

    &+.graph_bar {
        margin-top: 0.25em;
    }
}

/* histogramme */
table.histogramme {
    --bar_width: 30rem;

    font-size: 14px;
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    position: relative;

    thead {
        tr {
            box-shadow: 0 2px 2px -1px #000;
        }

        th {
            font-size: 11px;
            text-transform: uppercase;
            background: #fff;
            padding: 5px 4px 7px;
            line-height: 1em;

        }

        th:last-child:before,
        th:first-child:before {
            content: "";
            position: absolute;
            display: block;
            width: 2px;
            height: 20px;
            background: #fff;

            z-index: 13;
        }

        th:last-child:before {
            right: -1px;
        }

        th:first-child:before {
            left: -1px;
        }

    }

    th {
        font-weight: 400;

    }

    tbody th,
    td {
        padding: 5px 4px;
        vertical-align: top;
        line-height: 2.25em;
    }

    tr:nth-child(2n) {
        background-color: #f2f2f2;
    }

    td>.bar {
        font-size: 1em;
        height: 2.25em;
        line-height: 2.25em;
    }

    .logo {
        width: 26px;
        margin: -8px 0 -8px;
    }

    .fp_tp {

        margin: 3px 0 0 -27.5px;
    }
}

.palette {
    list-style: none;
    max-width: 1280px;
    margin: 0;
    padding: 0;
    clear: both;

    .darkcolor {
        color: #fff;
    }

    &:hover {
        li {
            opacity: 0.25;
        }
    }

    li {
        margin: 1px;
        padding: 0;
        height: 80px;
        width: 80px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        background-color: #eee;
        background-image: linear-gradient(45deg, #AAA 25%, transparent 25%, transparent 75%, #AAA 75%, #AAA),
            linear-gradient(45deg, #AAA 25%, transparent 25%, transparent 75%, #AAA 75%, #AAA);
        background-size: 20px 20px;
        background-position: 0 0, 10px 10px;

        font-size: 11px;
        word-break: break-all;

        &.similar {
            position: relative;
            z-index: 2;

            div {
                border: 3px dashed #000;
                margin: -3px;
            }

        }

        &.similar.darkcolor {
            div {
                border-color: #ececec;
            }
        }

        &:hover {
            transform: scale(1.5);
            font-size: 0.75em;
            z-index: 4;
            opacity: 1;
        }

        &.similar_over {
            z-index: 3;
            opacity: 1;

            div:after {
                content: "=";
                position: absolute;
                top: 35%;
                left: 43%;

                font-size: 2em;
            }
        }
    }

    div {
        border: 1px solid #fff;
        margin: -1px;
        height: 70px;
        width: 70px;
        padding: 5px;
        overflow: hidden;
    }

    p {
        position: absolute;
        bottom: 5px;
        right: 5px;
        text-align: right;
        font-size: 1.5em;
        margin: 0;

    }
}

.indicateur.similarcolors strong {
    border: 3px dashed #000;
    padding: 0 0.1em;
}

.tags_table input,
input[name="alltags"] {

    visibility: hidden;
    height: 1px;
    position: absolute;
    z-index: -1;

}

.tags_table {
    list-style: none;
    max-width: 1280px;
    margin: 0;
    padding: 0;
    clear: both;

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        clear: both;
    }

    li {
        float: left;
        margin: 1px 1px 0px 1px;
        position: relative;
        height: 60px;
        width: 72px;
    }



    p {
        border: 1px solid #eee;
        margin: 0;
        height: 50px;
        width: 64px;
        padding: 4px;
        overflow: hidden;
        font-size: 0.7em;
        position: relative;
        z-index: 1;

        label {
            display: block;
            width: 100%;
            height: 50px;
            cursor: pointer;
            text-transform: uppercase;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .close {
            position: absolute;
            top: 3px;
            right: 3px;
            width: 50px;
            font-weight: 600;
            display: none;
            text-align: right;
            text-transform: none;
            font-size: 17px;
        }

        .definition {
            display: none;
            line-height: 1.3em;
            background: rgb(238, 238, 238, 0.8);
            margin: 0 -4px -4px;
            padding: 10px;
            font-size: 13px;
        }

    }

    li input:checked+p {
        transform: translate(0px, 0px);
        width: 213px;
        height: auto;

        z-index: 3;
        opacity: 1;
        font-size: 0.85em;


        label {
            font-weight: 600;
            height: 20px;
            padding-left: 4px;

        }

        .close,
        .definition {
            display: block;
            padding-bottom: 18px;
        }
    }

    li:nth-child(17n-1) input:checked+p {
        transform: translate(-73px, 0px);
    }

    li:nth-child(17n) input:checked+p {
        transform: translate(-148px, 0px);
    }

    p:after {
        content: "x " attr(data-count);
        display: block;
        position: absolute;
        bottom: 3px;
        right: 3px;
        text-transform: lowercase;
        font-size: 1.5em;
        word-spacing: -3px;
        font-weight: 600;
    }

    p.required[data-count="0"],
    p.unique:not([data-count="1"], [data-count="0"]) {
        border: 3px dashed red;
        margin: -2px -2px -3px;
        z-index: 2;

        .warning {
            display: block;
            color: red;
            font-weight: bold;
            margin: 8px 0;

            &:before {
                content: "alerte";
                font-family: a4i_ico;
                font-size: 1.5em;
                vertical-align: middle;
                margin-right: 0.15em;
            }

        }
    }

    p[data-count="0"] {
        opacity: 0.25;
        font-weight: 400;

        &:hover {
            opacity: 1;
        }
    }

    p[data-count="0"]:after {
        font-weight: 400;
    }

    .type-html {
        background-color: var(--type-html);
    }

    .type-meta {
        background-color: var(--type-meta);
    }

    .type-scripts {
        background-color: var(--type-scripts);
    }

    .type-webcomponent {
        background-color: var(--type-webcomponent);
    }

    .type-body {
        background-color: var(--type-body);
    }

    .type-content-section {
        background-color: var(--type-content-section);
    }

    .type-content-block {
        background-color: var(--type-content-block);
    }

    .type-content-inline {
        background-color: var(--type-content-inline);
    }

    .type-edition {
        background-color: var(--type-edition);
    }

    .type-media {
        background-color: var(--type-media);
    }

    .type-multimedia {
        background-color: var(--type-multimedia);
    }

    .type-svgmath {
        background-color: var(--type-svgmath);
    }

    .type-table {
        background-color: var(--type-table);
    }

    .type-formulaire {
        background-color: var(--type-formulaire);
    }

    .type-interaction {
        background-color: var(--type-interaction);
    }

    .deprecated {
        background-color: var(--deprecated);
    }
}