.v1p3p_synthese {


    .graph_donuts {
        grid-template-columns: 55% auto;
        gap: 0.75em;

        .donuts {
            margin: 0 0 0 0;
        }
    }

    .indicateur {
        margin: 1em 0 0.35em;
    }

    hr {
        border: none;
        border-bottom: 1px solid var(--grey-light);
        margin-bottom: 1em;
    }

    .midfloat {
        float: left;
        width: 70%;

        &+.btn {
            float: right
        }
    }

}

#first_party_domains {
    height: 5.5em;
    font-family: inherit;
    font-size: 1em;
}