//systeme de filtre pour les tabelaue des popins.
$filters-list: "eventsBound",
    "DOMqueriesByTagName",
    "DOMinserts",
    "DOMqueriesByQuerySelectorAll",
    "DOMqueriesById",
    "DOMmutationsAttributes",
    "DOMmutationsInserts",
    "DOMmutationsRemoves",
    "DOMqueriesByClassName",
    "eventsDispatch",
    "eventsTypeCLICK",
    "eventsTypeSCROLL",
    "eventsTypeMOUSE",
    "eventsTypeTOUCH",
    "eventsTypeKEY",
    "eventsTypeLOAD";

%activefilter {
    --bg: #fff;
    --color: #333;
    --border: var(--color);
    --icowidth: 24px;
    padding: 0 4px 0 0;
    display: inline-block;
}

@each $filter in $filters-list {
    #filter_#{$filter}:checked~.filter_system [for='filter_#{$filter}'] {
        @extend %activefilter;
    }

    #filter_#{$filter}:checked~.filter_system+table tr.#{$filter} {
        display: table-row;
    }
}

#openclose_filtersystem,
.filter_input {
    position: sticky;
    top: 0;
    opacity: 0;
    height: 0;
    width: 0;
    z-index: -1;
    display: none;
}

.filter_input:checked~.filter_system+table tbody tr:not(.excludeforfilter) {
    display: none;
}

#openclose_filtersystem:checked {
    &~.filteredform {
        .filter_system {
            position: sticky;
            z-index: 10;
            top: -6px;
            margin-bottom: 1em;
            background: #eee;
            box-shadow: 0px 5px 5px -5px #333;
            display: grid !important;


            .filter {
                display: inline-block;
            }

            .filters_group,
            .filters_group p {
                display: block;
            }
        }

        .filter_input:checked~.filter_system [type="reset"] {
            display: inline-block;
            opacity: 1;
        }
    }

    &~.filteredform .openclose_filtersystem {
        &>:nth-child(1) {
            display: none;
        }

        &>:nth-child(2) {
            display: inline-block;
        }
    }
}

.openclose_filtersystem {
    position: sticky;
    top: 7px;
    z-index: 11;
    margin: 13px 0 0 -50%;
    float: right;
    cursor: pointer !important;
    vertical-align: bottom;
    font-size: 14px;
    line-height: 22px;
    float: right;

    .ico {
        vertical-align: top;
        display: inline-block;
    }

    &>:nth-child(2) {
        display: none;
    }
}

.filter_system {
    float: left;
    width: 100%;
    font-size: 13px;
    line-height: 28px;
    padding: 1em;
    margin: 0 -1em;
    border-radius: 1em;
    transition: all 250ms ease-in 0s;
    display: block !important;


    p {
        margin: 0;
    }

    .entete {
        line-height: 13px;
        font-size: 14px;
        margin-bottom: .5em;
        padding-top: 5px;
    }

    .filters_group {
        display: inline;

        p {
            display: none;

        }
    }

    .filter {
        --bg: #d0cfd5;
        --color: #555;
        --border: var(--bg);
        --icowidth: 0;
        display: none;
        background: var(--bg);
        padding: 0 14px;
        border-radius: 3px;
        line-height: 21px;
        vertical-align: middle;
        color: var(--color);
        border: 1px solid var(--border);
        cursor: pointer;

        &:before {
            content: "check";
            font-family: a4i_ico, sans-serif;
            font-variant-ligatures: discretionary-ligatures;
            font-feature-settings: "dlig";
            line-height: 1em;
            font-size: 16px;
            vertical-align: text-bottom;
            width: var(--icowidth);
            overflow: hidden;
            display: inline-block;
            text-align: center;
        }
    }

    [type="reset"] {
        position: absolute;
        right: 140px;
        top: 12px;
        font-size: 14px;
        background: none;
        border: none;
        padding: 0;
        margin-right: 2em;
        cursor: pointer;
        color: #777;
        display: none;
        opacity: 0;
        transition-property: opacity, display;
        transition-timing-function: ease-in;
        transition-duration: .15s;
        transition-behavior: allow-discrete;



        .ico {
            margin: 0px 0 -1px;
            display: inline-block;
        }

        &:hover {
            color: #333;
        }


    }

}