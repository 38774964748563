@media (min-width: $mq-1) {

    #megamenu_openclose:checked~#wrapper #megamenu,
    dialog[open] {
        &:before {
            content: "";
            position: fixed;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            opacity: 0;
            transition: opacity 0.25s linear 0.25s, z-index 0s linear 0.55s;
            animation: rotation 1s linear infinite;
            z-index: 4;
        }
    }

    dialog[open] {
        &:before {
            top: .9em;
            right: 1.2em;
            border: 4px solid #333;
            border-bottom-color: transparent;
            background: #f2f2f2;
        }
    }

    #megamenu_openclose:checked~#wrapper #megamenu {
        &:before {
            top: 1em;
            right: 2.25em;
            border: 4px solid #eee;
            border-bottom-color: transparent;
        }
    }

    #megamenu_openclose:checked~#wrapper .acces_rapide_sticky {
        opacity: 1;
        transition: opacity 0.25s linear 0.25s
    }

    .loading {

        #megamenu_openclose:checked~#wrapper #megamenu,
        dialog[open] {
            &:before {
                z-index: 6;
                opacity: 1;
                transition: opacity 0.25s linear 0.25s, z-index 0s linear 0.25s;
            }

        }

        #megamenu_openclose:checked~#wrapper .acces_rapide_sticky {
            opacity: 0;
        }
    }

}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}