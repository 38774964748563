/* Entête de l'audit
avec les informations relatives au test dans la partie meta
et le screenshot de la page testée.
NB : cf cards.scss pour les cards dessous ces 2 éléments. */

.bigheader {
	position: relative;
	background: #0E1E2D;
	padding: 1em 20px 3em;
	//margin-bottom :3em;
	overflow: hidden;

	#megamenu+& {
		margin-top: -38px;
	}

	body:not(.waiting) &:before {
		content: "";
		position: absolute;
		z-index: 1;
		background-color: #0E1E2D;
		background-image: url("img/mc_digit_trans.webp");
		background-size: 100% 100%;
		width: 794px;
		height: 800px;
		left: 42%;
		top: 40px;
		opacity: 0.3;
	}

	.grid {
		position: relative;
		z-index: 3;
	}

	.meta {
		color: #fff;

		p {
			font-size: 0.8125em;
			margin: 0.5em 0;
			line-height: 2em;
		}

		a {
			color: #fff;
			text-decoration: none;
		}
	}

	h1 {

		font-size: 1.5em;
		line-height: 1.1em;
		margin: 3em 0 0;

		.tag {
			font-size: .3em;
			font-weight: normal;
		}
	}

	.tag.label {
		background: var(--orange);
	}

	.tag .ico {
		font-size: 14px;
		display: inline-block;
		margin: -2px 4px 0px 0;
		line-height: 14px;
		vertical-align: bottom;
	}



	.screenshot {
		position: relative;
		align-self: center;
		text-align: center;


		img {
			height: 100%;
			max-height: 10em;
			max-width: calc(100% - 1em);
			width: auto;
			border: 0.5em solid #000;
			border-radius: 0.75em;

		}

		&:hover:after {
			content: "filmstrip";
			position: absolute;
			font-family: a4i_ico;
			font-size: 50px;
			color: rgb(0 0 0 / 50%);
			left: 50%;
			top: 50%;
			margin: -25px 0 0 -25px;
			pointer-events: none;
		}
	}

	.cta {
		margin: 3em 0;
		position: relative;
		z-index: 3;

		.btn {
			margin: 0 0.5em;


		}
	}
}

@media (max-width: $mq-2 - 1px) {

	.waiting .bigheader .grid {
		display: block;

		.complete_milkshake {
			display: none;
		}
	}

}

@media (min-width: $mq-1 ) {
	.bigheader {
		padding-top: 0;

		#megamenu+& {
			margin-top: -56px;
		}


		.grid {
			max-width: 1326px;
			padding: 2em 0 0;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 40% 22% auto;
			gap: 0 1em;
		}



		.meta {
			grid-column: 1/3;
			align-self: center;

		}



		.screenshot {
			//grid-row: 1 / 3;
			grid-column: 3 / 4;

		}
	}

}

@media (min-width: $mq-2 ) {
	.bigheader {
		padding: 0 20px 0;



		h1 {
			font-size: 1.8em;
			margin-top: 1.5em;
		}

		.screenshot {
			img {
				max-height: 18em;
			}
		}
	}



}

@media (min-width: $mq-3 ) {
	.bigheader {
		h1 {
			font-size: 2.2em;

		}



		.meta {
			padding-left: 4em;
		}

	}

}