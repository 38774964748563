.acces_rapide_sticky {
	font-family: a4i_ico, sans-serif;
	position: sticky;

	font-weight: 400;
	font-size: 1.25em;
	padding: .06em .5em .14em;
	z-index: 20 !important;

	border-radius: 6px;

	&:before {
		content: "acces_rapide";
	}
}

#megamenu {
	box-sizing: border-box;
	counter-reset: vues;
	position: fixed;
	bottom: 100%;
	opacity: 0.5;
	height: 100vh;
	z-index: 19;
	transition: all 0.25s ease-out 0s;
	width: 100vw;
	overflow-y: auto;
	overflow-x: hidden;
	color: #fff;
	font-size: 16px;
	line-height: 1.5em;

	.scroll_disabled & {
		transition-delay: 0.25s;
	}

	.logo_mc {
		color: #fff;
		margin-bottom: 1em;
	}


	.intro {
		line-height: 1.4em;



		ol {
			list-style-type: upper-roman;
			padding-left: 26px;

			li {
				margin-bottom: 1em;
			}

		}
	}

	.tools {
		margin: 0;

		li {
			margin-bottom: 1em;
		}

		a {
			color: #fff;
			font-weight: 700;

			.ico {
				font-size: 1.25em;
			}
		}
	}

	.vues {
		line-height: 1.5em;
		font-size: 15px;
		padding-inline-start: 1.65em;

		li {
			counter-increment: vues;
			counter-set: vues;

			&::marker {
				content: counter(vues) ". ";
				color: #2C5881;
			}
		}

		a {
			color: #9dd0ff;

		}
	}

	ul {
		list-style: none;
		padding-inline-start: 0;
	}

	.bps {
		details {
			//color: #9DD0FF;
		}
	}

	.vue_color {
		color: #9DD0FF;
	}

	a {
		text-decoration: none;
	}

	a:not(.logo_mc):hover {
		text-decoration: underline;
	}

}

.nav_title_1 {
	font-size: 1.25em;
	font-weight: 700;
	margin: 0 0 0.5em;
	padding-bottom: 0.5em;
	border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.nav_title_2 {
	font-size: 1em;
	font-weight: 700;
	margin-bottom: 1em;
}

#megamenu_openclose:checked {

	&~#wrapper {
		//max-height: 100vh;
		//overflow: hidden;

		#megamenu {
			opacity: 1;
			bottom: 0;
		}

		.acces_rapide_sticky {
			//left: calc(100% - 3em - 18px);

			&:before {
				content: "close";
			}
		}

	}
}

/* barre de navigation sticky en haut 
n'apparit qu'une fois la liste des catégories passées*/
#navbar {
	position: sticky;
	height: 2.5em;
	background: #0E1E2D;
	color: var(--grey-medium);
	z-index: 8;
	top: -4em;
	opacity: 0;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 0.75em 1em;

	transition: 0.25s all ease-out;

	/*&:before {
		content: "";
		position: absolute;
		z-index: 1;
		background-color: #0e1e2d;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0.3;
	}*/



	a:hover {
		color: #fff;
	}
}

.scrolling {
	#navbar {
		top: 0;
		opacity: 1;
	}
}

@media (max-width: $mq-1 - 1px) {
	#acces_rapide_top {
		display: none;
	}

	.acces_rapide_sticky {
		left: calc(100% - 2.5em);
		top: -4px;
		margin-top: -4px;
	}

	#megamenu {
		background: var(--bluemed);


		.texte {
			display: none;
		}

		.intro {
			background: var(--bluedark);
			padding: 1em;
		}

		.metrics,
		.bps {
			background: var(--bluemed);
			padding: 1em;
		}

		summary {
			line-height: 2em;
			position: relative;

			&:after {
				content: "up";
				font-family: "a4i_ico";
				position: absolute;
				top: 0;
				right: 0;
				transform: rotate(-180deg);
				font-size: 1.5em;

				transition: all 0.25s ease-out;
			}
		}

		details[open]>summary:after {

			transform: rotate(0deg);
		}

		li a {
			line-height: 1.5em;
		}
	}

	#navbar {

		.test_url,
		.title {
			display: none;
		}
	}
}

@media (min-width: $mq-1) {
	#acces_rapide_top {
		position: absolute;
		top: 12px;
		right: 3em;
		font-size: 1em;
		z-index: 4;
		padding: .35em 1em;
		border-radius: 6px;
	}

	.acces_rapide_sticky {
		left: calc(100% - 3em);
		margin-top: 12px;
		top: 12px;
	}

	#dialog {
		.acces_rapide_sticky {
			position: fixed;
			right: calc((100vw - 1290px - 8em) / -2 + 0.75em);
			left: auto;
			top: -1.6em;
			opacity: 0;
			transition: opacity 0.15s linear 0.25s;
		}

		&[open] {
			.acces_rapide_sticky {
				opacity: 1;
			}
		}
	}

	#megamenu {
		--menuwidth: 748px;
		--nbrcols: 3;
		--adjust: 0.75em;


		display: grid;

		--prop: calc((100% - var(--menuwidth)) / 2 + (var(--menuwidth) / var(--nbrcols) - var(--adjust)));
		background: linear-gradient(90deg, #08121D 0%, #08121D var(--prop), #0e1e2d var(--prop));
		padding-bottom: 2em;

		.grid {

			justify-self: center;
			align-self: center;

			max-width: var(--menuwidth);
			padding: 2em 0 0;
			margin: 0 auto;
			display: grid;
			grid-template-columns: repeat(var(--nbrcols), 1fr);
			gap: 0 3em;
		}

		summary {
			pointer-events: none;

			a {
				pointer-events: all;
			}
		}


		.nav_title_1 {
			margin: 0.8em 0 1.5em;
			padding-bottom: 1em;
		}

		.logo_mc {
			margin-bottom: 0.65em;
		}
	}

}

@media (min-width: $mq-1 ) and (max-width: $mq-3 - 1px) {
	#megamenu {


		.intro {
			grid-row: span 2;
		}

		.texte {
			font-size: 14px;
		}

		.bps,
		.metrics {
			grid-column: span 2;
			padding-bottom: 1em;
		}

		.nav_title_1+div {
			columns: 2;
			column-gap: 3em;

			&>div,
			&>details {
				page-break-inside: avoid;
				break-inside: avoid-column;
			}
		}
	}
}

@media (min-width: $mq-3) {
	#megamenu {
		--menuwidth: 1248px;
		--nbrcols: 4;
		--adjust: 1.25em;

		.texte {
			margin-bottom: 3em;
		}

		.grid {
			gap: 0 4em;
		}

		.bps {
			grid-column: span 2;

			details {
				margin-right: -80px
			}


			.nav_title_1+div {
				columns: 2;
				column-gap: 10em;

				&>details,
				&>div {
					page-break-inside: avoid;
					break-inside: avoid-column;
				}
			}
		}
	}



}

@media (min-width: $mq-4) {

	#megamenu {
		--menuwidth: 1334px;


	}
}