.poids_requetes {
    h4 {
        color: var(--grey-medium);
        margin: 0;
    }

    h3 {
        margin-top: 0;
    }

    .text_right {
        .btn {
            font-size: 14px;
        }
    }

    table {
        --bckg: #ccc;
        border-spacing: 4px;
        width: 100%;
        line-height: 1em;

        &.type {
            --maxprct: calc(max(var(--html), var(--css), var(--font), var(--image), var(--js), var(--video), var(--autre)));
            min-width: 280px;

            tbody th {
                text-align: right;
            }

        }

        &.domain {
            --maxprct: calc(max(var(--dom1), var(--dom2), var(--dom3), var(--dom4), var(--dom5), var(--dom6), var(--dom7), var(--dom8), var(--dom9), var(--dom10)));
            min-width: 280px;

            tbody {
                th {
                    text-align: left;
                    max-width: 120px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                td {
                    font-size: 0.85em;
                }
            }

        }

        &.weight {
            .bar {
                --bg1: #f5e2a6;
            }

            .firstparty .bar {
                --bg1: #f5cc47;
            }
        }


        &.request {
            .bar {
                --bg1: #c0e3f1;
            }

            .firstparty .bar {
                --bg1: #71c7e9;
            }
        }

        &~small {
            font-size: 11px;
            color: var(--grey-medium);
        }

        .firstparty {

            th,
            td {
                font-weight: bold;
            }
        }

        thead {
            th {
                font-size: 0.75em;
                text-align: left;
                color: var(--grey-medium);

            }
        }


        tbody {

            th,
            td {
                border-top: 1px dotted var(--grey-medium);
                padding: 0.25em 0.25em 0;
                vertical-align: middle;
            }

            th {
                font-weight: normal;
                font-size: 0.85em;
                padding: 0.5em 0.25em 0;
            }
        }
    }

    .value {
        text-align: right;
        padding-right: 1em;
    }

    .prct {
        --sector: 0%;

        width: 50%;
        font-size: 0.8em;

        /*.bar {
           
            display: block;
            padding:0.25em 0.15em 0;
            background: linear-gradient(90deg, var(--bckg) var(--sector), transparent var(--sector));

        }*/
        .bar {
            --max: var(--maxprct);
            --bar_width: 100%;
        }

        &.html .bar {
            --value1: var(--html);
            --bg1: #82b5fc;
        }

        &.css .bar {
            --value1: var(--css);
            --bg1: #b2ea94;
        }

        &.font .bar {
            --value1: var(--font);
            --bg1: #ff523e;
        }

        &.image .bar {
            --value1: var(--image);
            --bg1: #c49ae8;
        }

        &.js .bar {
            --value1: var(--js);
            --bg1: #fec584;
        }

        &.video .bar {
            --value1: var(--video);
            --bg1: #21c2a2;
        }

        &.autre .bar {
            --value1: var(--autre);
            --bg1: #c4c4c4;
        }

        @for $i from 0 through 10 {
            &.dom#{$i} .bar {
                --value1: var(--dom#{$i});
            }



        }



    }


}

@media (min-width: $mq-1 ) {
    .poids_requetes {
        .content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0 2em;


            h4,
            .actions {
                grid-column: 1/3;
            }
        }
    }

}

@media (min-width: $mq-2 ) {
    .poids_requetes {
        table {
            &.type {
                min-width: 320px;
            }

            &.domain {
                min-width: 360px;

                tbody th {
                    max-width: 180px;
                }
            }
        }

        .content {

            gap: 0 4em;
        }
    }

}

@media (min-width: $mq-3 ) {
    .poids_requetes {
        table {
            &.domain {
                min-width: 480px;

                tbody th {
                    max-width: 200px;
                }
            }
        }

    }

}