#guide_mode_emploi {

    .bp {
        border: none;
        pointer-events: none;

        summary:after {
            display: none;
        }

    }

    .code_colors {

        .gscore,
        .score {
            float: left;
            font-size: 1.45em;
            margin: 0 0.5em 0.5em 0;
        }

    }

    .encart {
        display: grid;

        padding: 1.25em 1.5em 0.5em;

        grid-template-columns: 60% auto;
        gap: 3em;
    }

}


#guide_reglages {

    form {
        padding: 0.25em 1.5em 1.25em;
        margin: 2em -1.5em;
        border: 1px dotted var(--grey-medium);
        border-radius: 1em;
    }
}