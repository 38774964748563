.switch {
    display: none;


}

.switch_btn {

    display: inline-block;
    position: relative;
    height: 22px;
    //pour les buttons
    background: none;
    border: none;
    color: inherit;

    font-size: 14px;
    line-height: 22px;

    cursor: pointer;

    &:before,
    &:after {
        position: absolute;
        cursor: pointer;
        content: "";
    }

    &:before {
        top: 0;

        bottom: 0;
        background: #d9d9d9;
        width: 38px;
        border-radius: 11px;
    }

    &:after {
        position: absolute;
        height: 18px;
        width: 18px;
        top: 2px;
        background: var(--nightblue);
        border-radius: 50%;
        transition: all .15s ease-out 0s;

    }

    &.switch_left {
        padding-left: 46px;

        &:before {
            left: 0;
        }

        &:after {
            left: 2px;
        }
    }

    &.switch_right {
        padding-right: 46px;

        &:before {
            right: 0;
        }

        &:after {
            right: 18px;
        }
    }

}

.switch:checked+label {
    &:after {
        background: var(--orange);
    }

    &.switch_left:after {
        left: 18px;
    }

    &.switch_right:after {
        right: 2px;
    }
}