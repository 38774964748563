.test_url {
    display: grid;
    grid-template-columns: 2em auto;
    gap: 1em;
    color: white;

    text-decoration: none;

    .bigheader & {
        margin-bottom: 2em;
    }

    #guide_reglages & {
        color: inherit;
    }

    dialog header & {

        color: var(--grey-dark);
        grid-column-start: 3;
        grid-row: span 2;
        padding: 0 1.5em;
        border-left: 1px solid #ccc;
        max-width: calc(100% - 5em);

    }

    #navbar & {
        --containerwidth: 320px;
        width: calc((100vw - var(--containerwidth)) / 2);
        max-width: 20em;

        span {
            font-size: 0.85em;
            color: #ddd;
        }
    }

    span {
        display: -webkit-box;
        text-decoration: underline;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        align-self: center;
        word-break: break-all;
    }

    &:before {
        font-family: "a4i_ico";
        content: "link";
        text-decoration: none !important;
        font-size: 1.25em;
        display: block;
        margin: 0 0.25em 0 0;
        background: var(--nightblue);
        line-height: 2em;
        width: 2em;
        text-align: center;
        border-radius: 6px;
        color: white;

    }
}

@media (max-width: $mq-3) {
    .test_url {
        #navbar & {
            span {
                display: none;
            }
        }

    }

}

@media (min-width: $mq-3) {
    .test_url {
        #navbar & {
            --containerwidth: 820px;
        }

    }

}

@media (min-width: $mq-4) {
    .test_url {
        #navbar & {
            --containerwidth: 960px;
        }

    }

}