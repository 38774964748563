#sources {
    background: var(--grey-light);
    padding : 4em;
    margin-top:3em;

    h2 {
        font-size : 1.5em;
    }
    table {
        width:100%;
        thead {
            th {
                color : var(--grey-medium);
                font-size: 0.85em;
                text-align: left;
            }

        }
        
        td { 
            border-top: 1px solid var(--grey-medium);
            padding: 0.5em 0;
            height: 4em;
            vertical-align: middle;
            
        }
           
        
      
    }
    a {
        color : var(--grey-dark);
        text-decoration: none;
        .ico {
            vertical-align: bottom;
        }
        &:hover{
            color: var(--orange)
        }
    }
    .mention {
        margin: 2em 0 0;
        color :var(--grey-dark);
        font-size: 0.85em;
    }
}
@media (max-width: $mq-1  ) { 
    #sources {
        display: none;
    }
}