/* systeme de popin 

dialog
	> container 
		> header
		> content

*/
dialog {
	position: fixed;
	z-index: 18;
	overflow: auto;
	background-color: #fff;
	pointer-events: none;
	transition: opacity 0.5s linear;
	border: none;
	opacity: 0;
	//transform: translateZ(0);
	transform: translate3d(0, 0, 0);

	&[open] {
		pointer-events: auto;
		opacity: 1;
	}

	&.close[open] {
		animation: slideout 0.25s forwards;
	}

	.container {
		display: none;

		&.open {
			display: block;
		}
	}

	header {
		margin: 0 0 1em;

		.logo_mc {
			position: absolute;
			top: 0.75em;
			left: 1em;
			font-size: 1.3em;
			color: inherit;
			padding: 0 1.25em 0 0;

		}

		.suptitle {
			font-weight: bold;
			margin: 0;
			color: #aaa;
			grid-column-start: 2;
			padding-right: 1.5em;

		}

		h2 {
			font-size: 1em;
			font-weight: bold;
			padding-right: 1.5em;
			margin: 0;
			grid-column-start: 2;
			grid-row-start: 3;
		}

		.shortcut_by_type {
			display: none;
		}

	}

	.content {
		scrollbar-width: thin;
		scrollbar-color: var(--grey-medium) #fff;

		//overflow: auto;

		.explication {
			font-size: 14px;
			line-height: 20px;
			margin: 0 0 1em;
		}


		.encart {
			padding: 1.25em;
			margin: 0 -1.25em 0;
			border-radius: 1em;
			background: #eee;
			color: #333;

			:first-child {
				margin-top: 0;
			}
		}






		.separator {
			padding-bottom: 1.5em;
			margin-bottom: 1em;
			border-bottom: 1px solid var(--grey-light);
		}
	}

	.actions {
		font-size: 14px;
		margin: 1em 0 0;
		padding-top: 1em;
		border-top: 1px dotted var(--grey-medium);
		text-align: right;
	}
}

#close-dialog {
	position: absolute;
	top: 4.75em;
	right: 0.5em;
	z-index: 5;
}



.small-link {
	font-size: 12px;
	display: block;
	text-align: center;
	width: 16em;
	padding-top: 1em;
	color: var(--grey-medium);
	text-decoration: underline;
}


[data-opendialog] {
	cursor: pointer;
}

.dialog_nav {
	--color: var(--grey-dark);
	--active-color: #000;

	border-bottom: 1px solid #ccc;
	list-style: none;
	margin: 0 0 1em;
	padding: 1em 0 0;
	font-size: 15px;
	overflow: hidden;
	height: 3.65em;
	box-sizing: border-box;



	ul {
		margin: 0;
		padding: 0;
		overflow-y: hidden;
		overflow-x: auto;
		white-space: nowrap;
	}

	li {
		display: inline-block;
		margin-right: 1em;
	}

	a {
		color: var(--grey-dark);
		text-decoration: none;
		display: block;
		cursor: pointer;
		margin-bottom: -1px;
		padding: 1.3em 0.25em 1em;
		text-align: center;
		opacity: 0.75;

		height: 3.65em;
		box-sizing: border-box;

		&.nav_open {
			color: var(--active-color);
			font-weight: 700;
			pointer-events: none;
			border-bottom: 4px solid var(--active-color);
			opacity: 1;

		}

		&:hover {
			color: var(--active-color);
		}
	}






}


@media (max-width: $mq-1 - 1px) {
	dialog {
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		padding: 1.5em;
		top: 0;

		header {
			.test_url {
				display: none;
			}
		}

		.xs-hidden {
			display: none !important;
		}

	}



}

@media (max-width: $mq-3 - 1px) {
	dialog {
		.md-hidden {
			display: none !important;
		}

	}



}

@media (min-width: $mq-1 ) {
	dialog {


		max-width: 1120px;
		min-width: 300px;
		min-height: 400px;

		//on ne put centrer sans avoir un rendu blurry !!!
		//top: 50vh;
		//transform: translate3d(0, -50%, 0);
		//obliger de partir du haut.

		top: 1.5em;

		border-radius: 1em;
		padding: 0em 2.5em 0;
		box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
		display: block;
		overflow: hidden;

		//la class ajouté au body si le container a la class mini
		.dialog_mini & {
			top: 5em;


			.container {
				width: fit-content;
				height: fit-content;

				.content {
					margin-top: 4em;

					&:before {
						content: none;
					}
				}

				header {
					display: block;
					padding: 1.5em 2.5em;
					height: auto;
				}
			}
		}

		.container {
			width: calc(100vw - 12em);
			height: calc(100vh - 11em);
			max-width: 100%;
		}

		header {
			background: #F2F2F2;
			margin: 0 0 0;
			padding: 0.25em 0 1em;
			display: grid;
			grid-template-columns: 2.5em fit-content(75%) auto;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 4;
			height: 6.9em;

			.logo_mc {
				left: 1.7em;
				border-right: 1px solid #ccc;
			}

		}

		.content {
			overflow: auto;
			min-height: 220px;
			max-height: calc(100vh - 14.25em);
			margin: 8em -2.5em 0;
			padding: .75em 2.5em 2.5em;
			width: 100%;

			&:before {
				content: "";
				position: fixed;
				top: 7.75em;
				left: 0;
				right: 0;
				height: 1em;
				z-index: 2;
				background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
			}

			&.thin {
				width: 320px
			}
		}

		.grid {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: 0 3em;

			.col-md-12 {
				grid-column: span 12;
			}


			.col-md-7 {
				grid-column: span 7;
			}

			.col-md-6 {
				grid-column: span 6;
			}

			.col-md-5 {
				grid-column: span 5;
			}



		}
	}

	dialog[open] {
		animation: slidein 0.25s;
	}


	.dialog_nav {
		padding: 0;
		grid-column: span 3;

		ul {
			margin: 0 3em 0 13em;
			padding: 0 0 0 1em;
		}

		li {
			margin-right: 0.25em;
		}
	}

	#close-dialog {
		top: 0.75em;
		right: 0.75em;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(242, 242, 242, 1) 1em, rgba(242, 242, 242, 1));
	}
}

@media (min-width: $mq-2 ) {
	dialog {
		header {
			h2 {
				font-size: 1.25em;
			}
		}
	}

	.dialog_nav {
		li {
			margin-right: 1em;
		}
	}
}

@media (min-width:$mq-3) {
	dialog {
		max-width: 1120px;

		.grid {
			.col-lg-12 {
				grid-column: span 12;
			}

			.col-lg-9 {
				grid-column: span 8;
			}

			.col-lg-8 {
				grid-column: span 8;
			}

			.col-lg-7 {
				grid-column: span 7;
			}

			.col-lg-6 {
				grid-column: span 6;
			}

			.col-lg-5 {
				grid-column: span 5;
			}

			.col-lg-4 {
				grid-column: span 4;
			}

			.col-lg-3 {
				grid-column: span 3;
			}

			.col-lg-2 {
				grid-column: span 2;
			}

			.row-lg-2 {
				grid-row: span 2;
			}

			.row-lg-3 {
				grid-row: span 3;
			}



			.row-lg-start-2 {
				grid-row-start: 2;
			}

			//système de separator
			.border_bottom {
				padding-bottom: 1em;
				border-bottom: 1px solid var(--grey-medium);
			}

			.border_top_inc {
				margin-top: -1px;
				border-top: 1px solid var(--grey-medium);
			}

		}

		header {
			.shortcut_by_type {
				display: block;
				position: absolute;
				right: 3em;
				top: 6.65em;

				a {
					text-decoration: none;
					--tagcolor: var(--grey-medium);
				}

				span {
					--tagcolor: var(--orange);
				}

				&+.test_url {
					padding-right: 14em;
				}
			}
		}
	}
}

@media (min-width:$mq-4) {
	dialog {


		max-width: 1290px;
	}

}

@keyframes slidein {
	from {

		transform: translate3d(-100%, 0, 0) skewX(30deg);
		opacity: 0;
	}

	65% {
		opacity: 1;
	}

	75% {

		transform: translate3d(0, 0, 0) skewX(20deg);

	}

	90% {

		transform: translate3d(-3%, 0, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}

}

@keyframes slideout {
	from {
		opacity: 1;
	}

	to {
		transform: translate3d(100%, 0%, 0) skewX(30deg);
		opacity: 0;
	}
}