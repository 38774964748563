/* Cards de l'entête
réprésente les 3 blocs introductif des métriques de perf 
et une carte résumé des scores de bonnes pratiques.*/
.card {
    font-size: 0.8em;
    border-radius: 0.75em;
    padding: 1.5em;
    position: relative;
    background: #08121dbf;
    color: #fff;


    .interact {
        a {
            text-decoration: none;
            display: block;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            padding: 8px 0;
            margin: -8px 0;
            cursor: pointer;
        }
    }

    .card_title {
        font-size: 1.15em;
        margin: 0 0 1em;
    }

    .indicateurs {
        display: grid;
        grid-template-columns: 38% 38% auto;
        gap: 1em 0;
        list-style-type: none;
        padding: 0 0 1em;
        margin: 0;

        li {
            font-size: 0.95em;

            abbr,
            span {
                display: block;
            }
        }

        .metric_name {
            color: var(--grey-medium);
            margin-bottom: 0.2em;
            white-space: nowrap;
        }

        .metric_top,
        .metric_value {
            font-weight: 600;
        }

        .metric_top {
            grid-column: 1/4;

            .score_name {
                display: block;

                font-size: 0.8em;
                color: var(--grey-medium);
                text-align: left;
                width: 10em;
                margin: 0 auto 8px;
                padding: 8px 0;

                .lght_logo {
                    float: left;
                    margin: 0 .25em 0 0;
                    width: 28px;
                }

            }
        }

        .score_top {
            text-align: center;
        }

        .colspan-2 {
            grid-column: span 2;
        }

        .others {
            border-top: 1px solid var(--grey-dark);
        }

        .big_gscore {
            width: 8.375em;
            height: 8.375em;
        }
    }

    &.card_perf {
        --marg: 1.35em;

        .cwv {
            .metric_value {
                font-size: 1.5em;
            }
        }




        .others {
            padding-top: var(--marg);
        }


    }

    &.card_eco {

        .ecoindex {
            font-size: 2.5em;
            margin: 0.225em;
            display: inline-block
        }

        .metric_value {
            font-size: 1.5em;

            small {
                font-weight: normal;
            }
        }
    }

    &.card_bp {


        li {
            text-align: center;
        }

        .indicateurs {
            gap: 1.35em 0;
        }



        .score_name {
            color: var(--grey-medium);
            margin-bottom: 0.2em;
            white-space: nowrap;
        }

        .gscore {
            margin: 0.2em auto 0;
            width: 3.75em;
            height: 3.75em;
            pointer-events: none;
        }
    }

    .btn_ico {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 1.35em;
        margin: 0;
        border-radius: 2em 0 10px 0;
        color: #fff;
        padding: 0.75em 0.35em 0.35em 0.75em;
        background: var(--orange);

        &:hover {
            color: #fff;
        }
    }



    .fp_tp {

        display: none;
        //opacity: 0.85;

        .card_title {
            font-size: 1em;
            line-height: 1.2em;
            margin: 1em 0 1em;
            text-transform: uppercase;
        }

        &:hover {
            //opacity: 1;
        }
    }

    .with_fp_tp & {


        .fp_tp {
            display: block;

        }

        &.card_perf {
            padding: 0;

            &>div {
                position: relative;
                padding: 1.5em 1.5em 0;


            }


            .fp_tp {
                padding: 0 1.5em 1.5em;
            }
        }

    }



    .bstat {

        color: var(--tagcolor);
        line-height: 1em;
        margin: 0.25em 0;

        span {
            font-size: 0.6em;
            color: #fff;
            display: inline-block;
            width: 2.5em;
            line-height: 2em;
            border-radius: 4px;
            background: var(--tagcolor);
            font-weight: 600;
            text-align: center;
            vertical-align: middle;
        }
    }

    .mostimpact {
        font-size: 0.95em;
        color: var(--grey-medium);


        .ltag {
            white-space: nowrap;
        }
    }
}



@media (max-width: 1099px) {
    .bigheader .grid-4-cards {
        display: grid;
        min-width: 0;
        overflow-y: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        margin: 1em -10px 0;
        gap: 0 1em;
        grid-template-columns: max(246px, 33%) auto max(200px, 33%);
        padding-bottom: 0.5em;

        .card {
            scroll-snap-align: start;
        }
    }

    .card {
        .metric_top {
            text-align: center;
        }

        //.mostimpact,
        .bar,
        .card_title br {
            display: none;
        }

        .bstat {
            display: inline-block;
            margin-right: 0.5em;
        }

        .fp_tp {
            border-top: 1px solid var(--grey-dark);
            padding: 0.75em 0 0;

            .card_title {
                margin-bottom: 0.5em
            }


        }

        .mostimpact {
            margin: 0
        }

        .bstat {
            font-size: 1.4em;
        }



        &.card_bp {
            min-width: 150px;

            .card_title {
                margin-bottom: 0;

                min-height: 2em;

            }

            .indicateurs {
                grid-template-columns: repeat(2, 1fr);
                gap: 0;
            }

            .score_top {
                grid-column: 1/3;

            }
        }
    }

}

@media (max-width: $mq-2 - 1px) {


    .card {


        .with_fp_tp &.card_perf {

            .score_name,
            .others {
                display: none !important;
            }
        }

        &.card_eco {
            .indicateurs {
                display: block;

                li {
                    margin-bottom: 1em;
                }

                li+li {
                    margin: 0 0 1.4em 2.5em;
                }

                li:last-child {
                    margin-bottom: 0;
                }

            }
        }
    }
}


@media (min-width: $mq-2) {
    .bigheader .grid-4-cards.with_fp_tp {

        grid-template-columns: max(246px, 33%) max(150px, 20%) auto;

    }


    .bigheader .grid-4-cards.with_fp_tp {
        grid-template-columns: 45% 20% auto;





        .others.colspan-2 {
            margin-right: 1.5em;
        }





    }

    .card {

        &.card_perf .indicateurs {
            grid-template-columns: repeat(5, 1fr);

            .score_top,
            .metric_top {
                grid-column: 1/3;
                grid-row: 1/4;


                .score_name {
                    margin: 0.5em 0 0 0;
                }
            }

        }



        &.card_eco {
            .indicateurs {
                grid-template-columns: repeat(2, 1fr);
            }

            .score_top {
                grid-column: 1/3;
                //margin-bottom: 0.15em;
            }

            .metric_value {
                font-size: 1.2em;
            }


        }

        &.card_bp {
            min-width: 230px;

            .indicateurs {
                grid-template-columns: repeat(3, 1fr);
            }



            .score_top {
                grid-column: 1/4;

            }
        }



        .btn_ico {

            /*bottom: 1em;
            right: 0.75em;*/

        }
    }

}

@media (min-width: 1100px) {
    .card {


        .indicateurs {
            gap: var(--marg) 0;
        }

        .mostimpact {
            margin-top: 1.45em;
        }

        /* .with_fp_tp &.card_perf {
            &>div .btn_ico {
                bottom: -0.25em;
                right: 0.75em;
            }

        }*/






        &.card_eco {
            .indicateurs {
                grid-template-columns: repeat(3, 1fr);
                gap: 1em 0;
            }

            .score_top {
                grid-column: 1/4;
                margin-bottom: 0.15em;
            }
        }

        &.card_bp {


            .indicateurs {
                grid-template-columns: repeat(5, 1fr);
            }



            .score_top {
                grid-column: 1/3;
                grid-row: 1/4;
                margin-bottom: 2.4em;
            }
        }

        .btn_ico {

            // bottom: 0.75em;


        }
    }

    .with_fp_tp .card.card_perf {
        display: grid;
        grid-template-columns: 68% auto;
        padding: 0;

        &>div:first-child {
            position: relative;
            padding: 1.5em 0 1.5em 1.5em;
            border-right: 1px solid var(--grey-dark);
            border-radius: 0 0 .6em;

        }

        .fp_tp {
            position: relative;
            padding: 1.5em 1.5em 1.5em 1em;


            transition: opacity 0.25s linear;

            .card_title {
                margin-top: 0
            }

            .bar {

                height: 0.8em;
                width: 100%;
            }
        }


    }




    .bstat {
        font-size: 1.8em;
    }



}

@media (min-width: $mq-3) {
    .card {
        font-size: 1em;

        .bstat {
            margin: 0.35em 0;
        }

        .mostimpact {
            margin-top: 1.4em;
        }
    }
}