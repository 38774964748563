.processing.content {


    .legendes {


        margin: 0;


        &>li:not(:first-child, :nth-child(3)) {
            border-top: 1px dotted var(--grey-medium);
            padding-top: 0.5em;
        }
    }


}

.btn_details {
    float: right;
    font-size: 13px;
    margin: 1em 0 0 2em;
}

@media (min-width:$mq-1) {
    .processing.content {


        .legendes {

            columns: 2;
            column-gap: 2em;
        }
    }
}